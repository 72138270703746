.note-edit-area {
  flex: 1;
  overflow-y: auto;
  white-space: pre-wrap;
  padding: 8px;
  resize: none;
  outline: none;
  border: 0;
}

/* prevents the default blue border from appearing when clicking */
.no-click-border:focus { 
  outline: none;
  border: 0;
}

.item-views-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
}

.empty-item-views-message {
  display: flex;
  flex-direction: column;
  min-height: 20vh;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: -1;
}

.empty-item-views-message__header {
  color: var(--color-default-56);
  margin: 8px 0px;
  font-size: 45px; font-weight: 400; line-height: 48px;
  text-align: center;
}
.empty-item-views-message__body {
  color: var(--color-default-56);
  text-align: center;
}

.empty-item-folders-message {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  min-height: 40px;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: -1;
}

.empty-item-folders-message__body {
  color: var(--color-default-56);
  text-align: center;
}
.menu {
  margin: 8px 6px;
}
.menu-contents {
  background-color: white;
  width: 100%;
  overflow-y: auto;
  padding: 16px 0px;
}
.hidden .menu-contents {
  display: none;
}
.menu-sizing {
  position: fixed;
  padding: 16px 0px;
  left: 0px;
  bottom: 100vmax;
}
.contact-area .chip {
  margin: 10px 2px 9px 2px;
}

.message__quoted-text {
  margin-top: 8px;
  padding: 16px 0px;
}

.message__quote-header {
  border-top: solid 1px var(--color-default-12);
  padding-top: 8px;
}

.message__quote-header b {
  width: 80px;
  line-height: 20px;
}
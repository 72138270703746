.board-lane {
  display: flex;
  flex-direction: column;
  position: relative;
  flex-shrink: 0;
  width: 256px;
  margin: 8px;
  box-shadow: var(--whiteframe-4px);
}
.board-lane-header {
  display: flex;
  flex-direction: row;
  min-height: 48px;
  align-items: center;
  box-shadow: var(--whiteframe-1dp)
}
.board-lane-body {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;
}
.board-lane-footer {
  display: flex;
  flex-direction: row;
  min-height: 48px;
}
.board-lane-footer button {
  flex : 1
}
.board-lane.reorder-target {
  background-color: var(--color-gray-300)
}
.board-lane-reordering-area {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
}
.board-lane.reorder-target.card-target .board-card {
  z-index: 2;
  position: relative;
}

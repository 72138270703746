.shareable-view {
  min-height: 128px;
  /* padding: 8px; */
  display: flex;
  flex-direction: column;
  height: 35vw;
  max-height: 28vh;
  margin: 0;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 400px) { 
  .shareable-view {width: 100%;} 
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 400px) {
  .shareable-view {width: 50%;}
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) { 
  .shareable-view {width: 33.332%;} 
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) { 
  .shareable-view {width: 25%; } 
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) { 
  .shareable-view {width: 20%; } 
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) { 
  .shareable-view {width: 15%; } 
}
.shareable-view .card {
  flex: 1;
  margin: 8px;
}
.shareable-view-preview {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: -16px;
  background-color: var(--color-gray-200)
}
.shareable-view-title {
  display: flex;
  flex-direction: row; 
}
.shareable-view.reorder-target, .shareable-view.reordering {
  position: relative;
}
.shareable-view.reorder-target .card {
  background-color: var(--color-gray-300)
}
.shareable-view.reorder-target .shareable-view-preview {
  background-color: var(--color-gray-500)
}
.feature-view-reordering-area {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
}

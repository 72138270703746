.message-group {
  display: flex;
  flex-direction: row;
  padding: 8px;
}

.message-group__list {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.message-group__list.system-message {
  flex-direction: row;
  flex: 1;
}

.chat-message__sent, .chat-message__recieved {
  flex: 1;
  white-space: pre-wrap;
  padding: 8px 12px;
  margin: 0px 8px 8px 8px;
  resize: none;
  outline: none;
  border: 0;
  border-radius: 16px;
  color: var(--color-default-87);
}

.chat-message__sent {
  align-self: flex-end;
  background-color: white;
}

.chat-message__recieved {
  align-self: flex-start;
  background-color: var(--color-gray-300)
}

.chat-message__system {
  flex: 1;
  white-space: pre-wrap;
  padding: 12px;
  margin: 0px -8px 8px -8px;
  resize: none;
  outline: none;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  border: 0;
  color: var(--color-default-56);
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.chat-editor {
  display: flex;
  flex-direction: row;
}

.chat-editor .input-container {
  border-radius: 8px;
  line-height: 20px;
  border: solid 1px var(--color-default-12);
  background-color: white;
  padding: 0px 4px;
}

.chat-editor textarea {
  border-bottom: none !important;
  background-color: white !important;
}

.message-group__time-area {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.system-message .message-group__time-area {
  padding: 12px 8px;
  margin: 0px -8px 8px -8px;
  resize: none;
  outline: none;
  text-align: center;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
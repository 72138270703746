.quicklist-section {
  position: relative;
  min-height: 56px;
}
.quicklist-section.selected {
  background-color : var(--color-gray-300)
}
.quicklist-section .input-container {
  padding: 0px 4px;
}
.quicklist-section input { 
  font-size: 20px;
  font-weight: 500;
  line-height: 56px;
  min-height: 56px;
  padding: 0px 2px;
  letter-spacing: .005em;
}
.quicklist-section-reordering-area {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-color: rgb(255,255,255,.21);
}
.quicklist-section-reordering-area.target {
  background-color: rgba(0,0,0,.21);
}
.quick-list-section__right-spacer {
  width: 48px;
}
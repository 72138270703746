.select-picker {
  flex: 1
}

.select-picker-menu {
  background-color: white;
  width: 100%;
  z-index: 100;
  overflow-y: auto;
}

.select-picker-menu.hidden {
  display: none;
}
.select-picker-menu-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 2px 16px;
  height: 48px;
  font-size: 16px;
}
.select-picker-menu-item .text, .select-picker-menu-item .placeholder-text {
  flex : 100%;
}
.select-picker-menu-item .placeholder-text {
  color: var(--color-default-38);
  margin-left: -12px;
}
.select-picker-menu-item .selected {
  color: rgb(96,125,139) 
}

.select-picker-menu-item .text {
  line-height: 24px;
  margin-left: 4px;
  display: inline-block;
}
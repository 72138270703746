.board-card {
  box-shadow: var(--whiteframe-2dp);
  border-radius: 4px;
  background-color: var(--color-primary);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin : 8px;
  min-height: 48px;
  flex-shrink: 0;
  transition: height 1s ease-in-out;
}

.board-card .card-body {
  background-color: white;
  min-height: 32px;
  padding: 8px;
  position: relative;
}
.quicklist-item {
  position: relative;
}
.quicklist-item.selected {
  background-color : var(--color-gray-300)
}
.quicklist-item-reordering-area {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-color: rgb(255,255,255,.21);
}
.quicklist-item-reordering-area.target {
  background-color: rgba(0,0,0,.21);
}

.quick-list-item__right-spacer {
  width: 48px;
}
.floating-action-button {
  background-color: var(--color-gray-200);
  border: none;
  border-radius: 50%;
  color: #000;
  height: 56px;
  width: 56px;
  margin: 0;
  padding: 16px;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0;
  text-align: center;
  line-height: 36px;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
  box-shadow :var(--whiteframe-6px);
  -webkit-tap-highlight-color: transparent;
}
.floating-action-button.mini { height: 40px;  width: 40px; padding: 8px}
/* .flat-button:hover { background-color : rgba(158,158,158,.2); } */
.floating-action-button:active { box-shadow :var(--whiteframe-12px); }

/* .flat-button.mini { min-width: 36px; padding: 0} */
.floating-action-button.primary { color: white; background-color : var(--color-primary); }
.floating-action-button.accent { color: white; background-color : var(--color-accent); }
.floating-action-button.warn { color: white; background-color : var(--color-warn); }
.colors-dark-background .floating-action-button { color : rgba(255,255,255,0.7); }

.floating-action-button.disabled, .floating-action-button.disabled:active {
  box-shadow : none;
  color : rgba(0,0,0,0.24);
  cursor: default;
}
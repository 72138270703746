.card {
  box-shadow: var(--whiteframe-2dp);
  border-radius: 4px;
  background-color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.card-title {
  padding: 16px;
  display: flex;
  flex-direction: row;
}
.card-content {
  padding: 16px;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}
.card-actions {
  border-top: solid 1px var(--color-default-12);
  display: flex;
  flex-direction: row;
}
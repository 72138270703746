.app-screen {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 256px;
  position: relative;
  overflow: hidden;
}
.flex {
  flex : 1;
}
div.column {
  display: flex;
  flex-direction: column;
}
.scrollable-column {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  max-height: 100%
}
div.row {
  display: flex;
  flex-direction: row;
}
div.wrap-row{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
div.centerItems{
  display: flex;
  justify-items: center;
  justify-content: center
}
div.rightItems{
  display: flex;
  justify-items: flex-end;
  justify-content: flex-end
}
.screen-header {
  min-height: 56px;
  max-height: 56px;
  display: flex;
  flex: 1;
  flex-direction: row;
  z-index: 3;
}
.screen-sub-header {
  height: 40px;
  box-shadow: var(--whiteframe-2dp);
  display: flex;
  flex-direction: row;
  z-index: 2;
  align-items: center;
  background-color: var(--color-gray-50);
}
.screen-sub-header.toggleable {
  margin-top: -40px;
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1);
  transform: translateY(40px)
}
.screen-sub-header.hidden {
  transform: translateY(0px);
}
.screen-header.primary {
  background-color: var(--color-primary);
}
.colors-dark-background {
  color: white;
}
.screenBody {
  overflow: none;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}
.screenFooter {
  min-height: 56px;
  max-height: 56px;
  display: flex;
  flex: 1;
  flex-direction: row;
  overflow-y: none;
  overflow-x: auto;
  border-top: 1px solid rgba(0, 0, 0, 0.12)
}
.section-right {
  align-self: flex-end;
}
.screen-fab-area {
  position: relative; 
  width: 100%;
  max-height: 1px;
}
.screen-fab-area .floating-action-button {
  position: absolute;
  bottom: 20px;
  right: 16px; 
}
.expansion-panel {
  box-shadow: var(--whiteframe-2dp);
  margin: 16px 8px;
  border-radius: 4px;
}

.expansion-panel__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 24px;
  height: 64px;
}

.expansion-panel__content {
  white-space: pre-wrap;
  padding: 0 24px;
}
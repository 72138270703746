.checkbox-container {
  position: relative;
  margin: 8px 16px;
  padding: 11px;
  min-width: 18px;
  height: 18px;
}
.checkbox-container label {
  height: 40px;
  width: 40px;
}
.checkbox-input {
  position: absolute;
  height: 40px;
  width: 40px;
  opacity: 0;
  top: 0px;
  left: 0px;
  margin: 0px;
}
.checkbox-box {
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 11px;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  border : solid;
  border-width: 2px;
  border-color: var(--color-default-56);
  background-color: rgba(0,0,0,0);
  transition: background-color 15ms;
}
.checkbox-box-check {
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 0px;
  padding: 13px;
  height: 14px;
  width: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 015ms;
}
.checkbox-label {
  margin-left: 24px;
}
.checkbox-box-check.checked {
  opacity: 100;
}
.checkbox-box-check svg {
  height: 24px;
  width: 24px;
}
.checkbox-box.checked, .checkbox-box.checked.primary {
  border-color: var(--color-primary);
  background-color: var(--color-primary);
}
.checkbox-box.checked.accent {
  border-color: var(--color-accent);
  background-color: var(--color-accent);
}
.checkbox-box.checked.warn {
  border-color: var(--color-warn);
  background-color: var(--color-warn);
}
.checkbox-box.checked.disabled {
  border-color: rgba(0,0,0,0);
  background-color: var(--color-default-26);
}
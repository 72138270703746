.feature-quick-list-items {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: none;
  transform: translateY(0);
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1);
}
.feature-quick-list-items.show-subheader {
  transform: translateY(40px);
}
.feature-quick-list-items-pad {
  height: 72px;
  flex-shrink: 0;
}
.feature-quick-list-items-pad.show-subheader{
  /* height: 112px; */
}
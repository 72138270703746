.multicontactchooser__linelabel {
  padding: 9px 4px;
  margin: 4px 2px -1px 2px;
  min-height: 30px;
  min-width: 40px;
  flex-direction: row;
  text-align: center;
  
  border-width: 0 0 0px 0; 
  line-height: 26px;
  height: 30px;
  
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.010em;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  outline: none;
}
.multicontactchooser__row {
  display: flex;
  min-height: 30px;
  flex: 1;
  flex-direction: row;
  padding: 9px 4px;
  margin: 4px 2px -1px 2px;
  line-height: 26px;
  height: 30px;
}
.multicontactchooser__row .multicontactchooser__linelabel {
  padding: 0px 8px 0px 0px;
  margin: 0;  
}
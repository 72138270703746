.contact-summary {
  display: flex;
  flex-direction: row;
  padding: 8px;
}

.contact-summary__content {
  display: flex;
  flex-direction: column;
  align-content: center;
  flex: 1;
} 

.contact-summary__avatar {
  margin-right: 8px;
}
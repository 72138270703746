.typography-h1, .typography-h2, .typography-h3, .typography-h4, .typography-h5, .typography-h6 {
  margin-bottom: 1rem; margin-top: 1rem;
}
.typography-h4 { font-size: 112px; letter-spacing: -1.12px; font-weight: 300; line-height: 112px; }
.typography-h3 { font-size: 56px; font-weight: 400; letter-spacing: -.005em; line-height: 56px; }
.typography-h2 { font-size: 45px; font-weight: 400; line-height: 64px; }
.typography-h1 { font-size: 34px; font-weight: 400; line-height: 40px; }
.typography-headline { font-size: 24px; font-weight: 400; line-height: 32px;}
.typography-title { font-size: 20px; font-weight: 500; line-height: 28px; letter-spacing: .005em;}
.typography-subtitle { font-size: 16px; letter-spacing: .0em; font-weight: 400; line-height: 24px; }
.typography-subtitle2 { font-size: 16px; letter-spacing: .0em; font-weight: 500; line-height: 24px; }
.typography-body, .typography-body-medium, .typography-body-light { font-size: 14px; letter-spacing: .01em; font-weight: 400; line-height: 20px; }
.typography-body-light { color: var(--color-default-56)}
.typography-body-medium { color: var(--color-default-87)}
.typography-body-bold { font-size: 14px; letter-spacing: .01em; font-weight: 500; line-height: 24px}
.typography-caption { font-size: 12px; letter-spacing: .02em; line-height: 16.8px; color: var(--color-default-87)}
.typography-caption-light { font-size: 12px; letter-spacing: .02em; line-height: 16.8px; color: var(--color-default-56)}
.alpha-87 { color: var(--color-default-87)}

.colors-dark-background .typography-body-medium, .colors-dark-background .alpha-87, .colors-dark-background .typography-caption {
   color: var(--color-white-default-87)
}
.colors-dark-background .typography-body-light, .colors-dark-background .typography-caption-light {
  color: var(--color-white-default-56)
}
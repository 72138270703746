.flat-button {
  background-color: rgba(0,0,0,0);
  border: none;
  border-radius: 2px;
  color: #000;
  height: 36px;
  margin: 6px 8px;
  min-width: 64px;
  padding: 0 16px;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0;
  text-align: center;
  line-height: 36px;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  justify-content: center;
  -webkit-tap-highlight-color: transparent;
}
.flat-button:hover { background-color : rgba(158,158,158,.2); }
.flat-button:active { background-color :rgba(158,158,158,.4); }

.flat-button.disabled:hover, .flat-button.disabled:active { 
  background : none;
  cursor: default; 
}

.flat-button.primary, .colors-dark-background .flat-button.primary { color : var(--color-primary); }
.flat-button.accent, .colors-dark-background .flat-button.accent { color : var(--color-accent); }
.flat-button.warn, .colors-dark-background .flat-button.warn { color : var(--color-warn); }

.colors-dark-background .flat-button { color : rgba(255,255,255,0.7); }
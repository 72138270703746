.time-popup-header {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 60px;
  min-height : 64px;
  font-weight: 300;
}

.popup-panel .header h1 {
  margin : 0px;
}
.popup-panel .body {
  flex : 1;
  padding : 16px;
}
.clock-circle-holder {
  display: flex;
  flex-direction: column;
}
.clock-circle {
  border-radius: 50%;
  width: 256px;
  height: 256px;
  background-color: #EEEEEE;
  align-self: center;
  vertical-align: center;
  position: relative;
}
.clock-circle-dot {
  position: absolute;
  border-radius: 50%; 
  height: 6px; width: 6px;
  margin: auto;
  left: 0; right: 0; top : 0; bottom: 0;
  background-color: #607d8b;
}
.clock-circle-hand {
  position: absolute;
  width: 256px; height: 256;
}
.time-circle {
  z-index: 0;
  position: absolute;
  width : 32px;
  height : 32px;
  border-radius : 50%;
  text-align : center;
  line-height : 32px;
}
.click-target {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 8px;
  left: 8px;
  border-radius: 50%;
}
.time-circle-number {
  z-index: 1;
}
.time-circle-dot, .time-circle-dot-hidden {
  margin: 15px;
  border-radius: 50%; 
  height: 4px; width: 4px;
}
.time-circle-dot { background-color: white; }
.time-circle-dot-hidden { background-color: none; }

.clock-right-container {
  flex:1;
  flex-direction: row;
  display: flex;
  align-items: center;
}
.clock-am-pm-container {
  display: flex;
  flex-direction: column;
  margin: 6px 8px;
}

.clock-time { color: rgba(255,255,255,.54); }
.clock-time:hover { color: rgba(255,255,255,.71); }
.clock-time:active { color: rgba(255,255,255,.87); }
.clock-time-selected { color: rgba(255,255,255,.87); }
.clock-time-selected:active { color: rgba(255,255,255,1);}

/* Override specific to time picker */
.clock-am-pm-container .flat-button {
  height: 24px; 
  line-height: 24px
}
.date-panel {
  position: fixed;
  top: 0;
  left: 0;
}
.date-panel__week, .day-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.month-header {
  margin: 4px 0;
  height: 48px;
  display: flex;
  flex-direction: row;
}
.month-header__month {
  font-size: 16px;
  color: var(--color-default-87);
  text-align: center;
  flex: 1;
  align-self: center;
}

.day-header__day, .date-panel__week .day, .date-panel__week .day--empty, .date-panel__week .day--selected {
  min-width: 36px;
  min-height: 36px;
  text-align: center;
  vertical-align: center;
  line-height: 36px;
  border-radius: 50%;
  font-size: 14px;
  margin: 0 2px;
}
.day-header__day {
  font-size: 12px;
  color: var(--color-default-38)
}
.date-panel__week .day:hover {
  background-color: var(--color-default-12);
}

.date-panel__week .day--selected {
  background-color: var(--color-primary);
  color : white;
}
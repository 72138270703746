.popup-panel.standard-width {
  min-width: 60%;
}

.popup-panel {
  display : flex;
  flex-flow : column;
  pointer-events: auto; 
  background-color : white;
  margin: auto;
  border-radius: 4px;
}

.popup-panel-container {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  pointer-events: none;
  display : flex;
  z-index: 100;
}
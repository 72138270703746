.board-preview {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 100%;
  padding: 4px 0px;
}

.board-preview-lane {
  background-color: white;
  border-radius: 1px;
  max-width: 80px;
  min-width: 80px;
  margin: 0px 2px;
  height: 100%;
  font-size: 10px;
  box-shadow: var(--whiteframe-1dp)
}

.board-preview-lane-header {
  border-bottom: 1px solid var(--color-gray-500);
  padding: 0px 2px;
  line-height: 20px;
}

.board-preview-card {
  color: white;
  background-color: var(--color-green-500);
  border-radius: 1px;
  margin: 4px 2px;
  max-height: 16px;
  line-height: 16px;
  overflow: hidden;
  font-size: 10px;
  padding: 0px 2px;
  box-shadow: var(--whiteframe-1dp) 
}
.menu-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 16px;
  height: 48px;
  font-size: 15px;
}
.menu-item.selected, .menu-item:hover { background-color : rgb(238,238,238); }
.menu-item:active { background-color :rgb(199,199,199); }
.menu-item.disabled:hover, .menu-item.disabled:active { 
  background : none;
  cursor: default; 
}
.menu-item.primary, .colors-dark-background .menu-item.primary { color : var(--color-primary); }
.menu-item.accent, .colors-dark-background .menu-item.accent { color : var(--color-accent); }
.menu-item.warn, .colors-dark-background .menu-item.warn { color : var(--color-warn); }
.raised-button {
  background-color: var(--color-gray-200);
  border: none;
  border-radius: 2px;
  color: #000;
  height: 36px;
  margin: 6px 8px;
  min-width: 64px;
  padding: 0 16px;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0;
  text-align: center;
  line-height: 36px;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
  box-shadow :var(--whiteframe-2dp);
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  justify-content: center;
  -webkit-tap-highlight-color: transparent;
}
/* .flat-button:hover { background-color : rgba(158,158,158,.2); } */
.raised-button:active { box-shadow :var(--whiteframe-8px); }

.raised-button.primary { color: white; background-color : var(--color-primary); }
.raised-button.accent { color: white; background-color : var(--color-accent); }
.raised-button.warn { color: white; background-color : var(--color-warn); }

.colors-dark-background .raised-button { color : rgba(255,255,255,0.7); }

.raised-button.disabled, .raised-button.disabled:active { 
  box-shadow : none; 
  color : rgba(0,0,0,0.24);
  cursor: default;
}
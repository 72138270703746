/*https://material.io/design/color/the-color-system.html#*/
:root {
  --color-clear: #00000000;

  --color-primary: #607d8b;
  --color-primary-87: #607d8bDE;
  --color-primary-56: #607d8b8f;
  
  --color-accent: #FFAB00;
  --color-accent-87: #FFAB00DE;

  --color-warn : #F44336;
  --color-warn-87: #F44336DE;

  --color-default: #000000;
  --color-default-87: #000000DE;
  --color-default-56: #0000008f;
  --color-default-38: #00000061;
  --color-default-26: #00000042;
  --color-default-12: #0000001f;

  --color-white-default: #ffffff;
  --color-white-default-87: #ffffffDE;
  --color-white-default-56: #ffffff8f;
  --color-white-default-38: #ffffff61;
  --color-white-default-26: #ffffff42;
  --color-white-default-12: #ffffff1f;

  --color-red-50 : #FFEbEE;
  --color-red-100 : #FFcDD2;
  --color-red-200 : #EF9a9a;
  --color-red-300 : #E57373;
  --color-red-400 : #EF5350;
  --color-red-500 : #F44336;
  --color-red-600 : #E53935;
  --color-red-700 : #D32F2F;
  --color-red-800 : #c62828;
  --color-red-900 : #b71c1c;
  --color-red-a100 : #FF8a80;
  --color-red-a200 : #FF5252;
  --color-red-a400 : #FF1744;
  --color-red-a700 : #D50000;
  
  --color-pink-50 : #FcE4Ec;
  --color-pink-100 : #F8bbD0;
  --color-pink-200 : #F48Fb1;
  --color-pink-300 : #F06292;
  --color-pink-400 : #Ec407a;
  --color-pink-500 : #E91E63;
  --color-pink-600 : #D81b60;
  --color-pink-700 : #c2185b;
  --color-pink-800 : #aD1457;
  --color-pink-900 : #880E4F;
  --color-pink-a100 : #FF80ab;
  --color-pink-a200 : #FF4081;
  --color-pink-a400 : #F50057;
  --color-pink-a700 : #c51162;
  
  --color-purple-50 : #F3E5F5;
  --color-purple-100 : #E1bEE7;
  --color-purple-200 : #cE93D8;
  --color-purple-300 : #ba68c8;
  --color-purple-400 : #ab47bc;
  --color-purple-500 : #9c27b0;
  --color-purple-600 : #8E24aa;
  --color-purple-700 : #7b1Fa2;
  --color-purple-800 : #6a1b9a;
  --color-purple-900 : #4a148c;
  --color-purple-a100 : #Ea80Fc;
  --color-purple-a200 : #E040Fb;
  --color-purple-a400 : #D500F9;
  --color-purple-a700 : #aa00FF;
  
  --color-deep-purple-50 : #EDE7F6;
  --color-deep-purple-100 : #D1c4E9;
  --color-deep-purple-200 : #b39DDb;
  --color-deep-purple-300 : #9575cD;
  --color-deep-purple-400 : #7E57c2;
  --color-deep-purple-500 : #673ab7;
  --color-deep-purple-600 : #5E35b1;
  --color-deep-purple-700 : #512Da8;
  --color-deep-purple-800 : #4527a0;
  --color-deep-purple-900 : #311b92;
  --color-deep-purple-a100 : #b388FF;
  --color-deep-purple-a200 : #7c4DFF;
  --color-deep-purple-a400 : #651FFF;
  --color-deep-purple-a700 : #6200Ea;
  
  --color-indigo-50 : #E8EaF6;
  --color-indigo-100 : #c5caE9;
  --color-indigo-200 : #9Fa8Da;
  --color-indigo-300 : #7986cb;
  --color-indigo-400 : #5c6bc0;
  --color-indigo-500 : #3F51b5;
  --color-indigo-600 : #3949ab;
  --color-indigo-700 : #303F9F;
  --color-indigo-800 : #283593;
  --color-indigo-900 : #1a237E;
  --color-indigo-a100 : #8c9EFF;
  --color-indigo-a200 : #536DFE;
  --color-indigo-a400 : #3D5aFE;
  --color-indigo-a700 : #304FFE;
  
  --color-blue-50 : #E3F2FD;
  --color-blue-100 : #bbDEFb;
  --color-blue-200 : #90caF9;
  --color-blue-300 : #64b5F6;
  --color-blue-400 : #42a5F5;
  --color-blue-500 : #2196F3;
  --color-blue-600 : #1E88E5;
  --color-blue-700 : #1976D2;
  --color-blue-800 : #1565c0;
  --color-blue-900 : #0D47a1;
  --color-blue-a100 : #82b1FF;
  --color-blue-a200 : #448aFF;
  --color-blue-a400 : #2979FF;
  --color-blue-a700 : #2962FF;
  
  --color-light-blue-50 : #E1F5FE;
  --color-light-blue-100 : #b3E5Fc;
  --color-light-blue-200 : #81D4Fa;
  --color-light-blue-300 : #4Fc3F7;
  --color-light-blue-400 : #29b6F6;
  --color-light-blue-500 : #03a9F4;
  --color-light-blue-600 : #039bE5;
  --color-light-blue-700 : #0288D1;
  --color-light-blue-800 : #0277bD;
  --color-light-blue-900 : #01579b;
  --color-light-blue-a100 : #80D8FF;
  --color-light-blue-a200 : #40c4FF;
  --color-light-blue-a400 : #00b0FF;
  --color-light-blue-a700 : #0091Ea;
  
  --color-cyan-50 : #E0F7Fa;
  --color-cyan-100 : #b2EbF2;
  --color-cyan-200 : #80DEEa;
  --color-cyan-300 : #4DD0E1;
  --color-cyan-400 : #26c6Da;
  --color-cyan-500 : #00bcD4;
  --color-cyan-600 : #00acc1;
  --color-cyan-700 : #0097a7;
  --color-cyan-800 : #00838F;
  --color-cyan-900 : #006064;
  --color-cyan-a100 : #84FFFF;
  --color-cyan-a200 : #18FFFF;
  --color-cyan-a400 : #00E5FF;
  --color-cyan-a700 : #00b8D4;
  
  --color-teal-50 : #E0F2F1;
  --color-teal-100 : #b2DFDb;
  --color-teal-200 : #80cbc4;
  --color-teal-300 : #4Db6ac;
  --color-teal-400 : #26a69a;
  --color-teal-500 : #009688;
  --color-teal-600 : #00897b;
  --color-teal-700 : #00796b;
  --color-teal-800 : #00695c;
  --color-teal-900 : #004D40;
  --color-teal-a100 : #a7FFEb;
  --color-teal-a200 : #64FFDa;
  --color-teal-a400 : #1DE9b6;
  --color-teal-a700 : #00bFa5;
  
  --color-green-50 : #E8F5E9;
  --color-green-100 : #c8E6c9;
  --color-green-200 : #a5D6a7;
  --color-green-300 : #81c784;
  --color-green-400 : #66bb6a;
  --color-green-500 : #4caF50;
  --color-green-600 : #43a047;
  --color-green-700 : #388E3c;
  --color-green-800 : #2E7D32;
  --color-green-900 : #1b5E20;
  --color-green-a100 : #b9F6ca;
  --color-green-a200 : #69F0aE;
  --color-green-a400 : #00E676;
  --color-green-a700 : #00c853;
  
  --color-light-green-50 : #F1F8E9;
  --color-light-green-100 : #DcEDc8;
  --color-light-green-200 : #c5E1a5;
  --color-light-green-300 : #aED581;
  --color-light-green-400 : #9ccc65;
  --color-light-green-500 : #8bc34a;
  --color-light-green-600 : #7cb342;
  --color-light-green-700 : #689F38;
  --color-light-green-800 : #558b2F;
  --color-light-green-900 : #33691E;
  --color-light-green-a100 : #ccFF90;
  --color-light-green-a200 : #b2FF59;
  --color-light-green-a400 : #76FF03;
  --color-light-green-a700 : #64DD17;
  
  --color-lime-50 : #F9FbE7;
  --color-lime-100 : #F0F4c3;
  --color-lime-200 : #E6EE9c;
  --color-lime-300 : #DcE775;
  --color-lime-400 : #D4E157;
  --color-lime-500 : #cDDc39;
  --color-lime-600 : #c0ca33;
  --color-lime-700 : #aFb42b;
  --color-lime-800 : #9E9D24;
  --color-lime-900 : #827717;
  --color-lime-a100 : #F4FF81;
  --color-lime-a200 : #EEFF41;
  --color-lime-a400 : #c6FF00;
  --color-lime-a700 : #aEEa00;
  
  --color-yellow-50 : #FFFDE7;
  --color-yellow-100 : #FFF9c4;
  --color-yellow-200 : #FFF59D;
  --color-yellow-300 : #FFF176;
  --color-yellow-400 : #FFEE58;
  --color-yellow-500 : #FFEb3b;
  --color-yellow-600 : #FDD835;
  --color-yellow-700 : #Fbc02D;
  --color-yellow-800 : #F9a825;
  --color-yellow-900 : #F57F17;
  --color-yellow-a100 : #FFFF8D;
  --color-yellow-a200 : #FFFF00;
  --color-yellow-a400 : #FFEa00;
  --color-yellow-a700 : #FFD600;
  
  --color-amber-50 : #FFF8E1;
  --color-amber-100 : #FFEcb3;
  --color-amber-200 : #FFE082;
  --color-amber-300 : #FFD54F;
  --color-amber-400 : #FFca28;
  --color-amber-500 : #FFc107;
  --color-amber-600 : #FFb300;
  --color-amber-700 : #FFa000;
  --color-amber-800 : #FF8F00;
  --color-amber-900 : #FF6F00;
  --color-amber-a100 : #FFE57F;
  --color-amber-a200 : #FFD740;
  --color-amber-a400 : #FFc400;
  --color-amber-a700 : #FFab00;
  
  --color-orange-50 : #FFF3E0;
  --color-orange-100 : #FFE0b2;
  --color-orange-200 : #FFcc80;
  --color-orange-300 : #FFb74D;
  --color-orange-400 : #FFa726;
  --color-orange-500 : #FF9800;
  --color-orange-600 : #Fb8c00;
  --color-orange-700 : #F57c00;
  --color-orange-800 : #EF6c00;
  --color-orange-900 : #E65100;
  --color-orange-a100 : #FFD180;
  --color-orange-a200 : #FFab40;
  --color-orange-a400 : #FF9100;
  --color-orange-a700 : #FF6D00;
  
  --color-deep-orange-50 : #FbE9E7;
  --color-deep-orange-100 : #FFccbc;
  --color-deep-orange-200 : #FFab91;
  --color-deep-orange-300 : #FF8a65;
  --color-deep-orange-400 : #FF7043;
  --color-deep-orange-500 : #FF5722;
  --color-deep-orange-600 : #F4511E;
  --color-deep-orange-700 : #E64a19;
  --color-deep-orange-800 : #D84315;
  --color-deep-orange-900 : #bF360c;
  --color-deep-orange-a100 : #FF9E80;
  --color-deep-orange-a200 : #FF6E40;
  --color-deep-orange-a400 : #FF3D00;
  --color-deep-orange-a700 : #DD2c00;
  
  --color-brown-50 : #EFEbE9;
  --color-brown-100 : #D7ccc8;
  --color-brown-200 : #bcaaa4;
  --color-brown-300 : #a1887F;
  --color-brown-400 : #8D6E63;
  --color-brown-500 : #795548;
  --color-brown-600 : #6D4c41;
  --color-brown-700 : #5D4037;
  --color-brown-800 : #4E342E;
  --color-brown-900 : #3E2723;

  --color-gray-50 : #FAFAFA;
  --color-gray-100 : #F5F5F5;
  --color-gray-200 : #EEEEEE;
  --color-gray-300 : #E0E0E0;
  --color-gray-400 : #BDBDBD;
  --color-gray-500 : #9E9E9E;
  --color-gray-600 : #757575;
  --color-gray-700 : #616161;
  --color-gray-800 : #424242;
  --color-gray-900 : #212121;
    
  --color-blue-gray-50 : #EcEFF1;
  --color-blue-gray-100 : #cFD8Dc;
  --color-blue-gray-200 : #b0bEc5;
  --color-blue-gray-300 : #90a4aE;
  --color-blue-gray-400 : #78909c;
  --color-blue-gray-500 : #607D8b;
  --color-blue-gray-600 : #546E7a;
  --color-blue-gray-700 : #455a64;
  --color-blue-gray-800 : #37474F;
  --color-blue-gray-900 : #263238;
}

.colors-fill-primary {
  background-color: #607d8b;
  color : #FFF
}

.colors-primary { color : var(--color-primary) }
.colors-accent { color : var(--color-accent) }
.colors-warn { color : var(--color-warn) }
.quick-list-preview {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  padding-top: 8px;
}

.quick-list-preview__item {
  font-size: 10px;
  line-height: 16px;
  padding: 0px 6px;
  line-height: 20px;
  display: flex;
  flex-direction: row
}

.quick-list-preview__section {
  font-size: 12px;
  border-bottom: 1px solid var(--color-default-12);
  padding: 0 2px 0 35px;
  margin: 0 0 2px 0;
  line-height: 24px;
  font-weight: 500;
}

.quick-list-preview__checkbox {
  margin: 4px 10px 4px 6px;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  border: solid;
  border-width: 1px;
  border-color: var(--color-default-56);
}

.quick-list-preview__checkbox--checked {
  margin: 4px 10px 4px 6px;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  border: solid;
  border-width: 1px;
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  position: relative;
}

.quick-list-preview__checkbox--checked svg {
  position: absolute
}
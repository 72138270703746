.board-card {
  box-shadow: var(--whiteframe-2dp);
  border-radius: 4px;
  background-color: var(--color-primary);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin : 8px;
  min-height: 48px;
  flex-shrink: 0;
  transition: height 1s ease-in-out;
}

.board-card__body {
  margin-top: -8px;
  background-color: white;
  min-height: 32px;
  position: relative;
}

.board-card__footer {
  background-color: white;
  min-height: 32px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.board-card-reordering-area {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
}
.board-card-reordering-area.target {
  background-color: rgba(0,0,0,.21)
}
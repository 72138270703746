.shareable-views-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 100%;
}

.shareable-views-list-header {
  height: 56px;
  margin-left: 8px;
}
/*
  Modal Bottom/Side Sheet, Top Sheet, Side Sheet   16dp
  Side Nav  16dp
  Normal Bottom/Side Sheet, Top Sheet, Side Sheet   12dp
  Dialog    24dp
  Nav Bar, App Bar, Header Bar    8dp
  Top App Bar   4dp
  Sub App Bar 2dp
  FAB   6dp, 12dp
  Loading Indicator 3dp
  Menus   6dp
  Raised Button   2dp, 8dp 
  Card  1dp   Lifted: 8dp 
  Search Bar    1dp
*/

/* https://material-component.github.io/material-components-web-catalaog/#/component/elevation*/
:root {
  --whiteframe-1dp: 
    0px 2px 1px -1px rgba(0, 0, 0, 0.2), 
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --whiteframe-2dp: 
    0px 3px 1px -2px rgba(0, 0, 0, 0.2), 
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --whiteframe-3dp:
    0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14),
    0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --whiteframe-4px:
    0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --whiteframe-5px:
    0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14),
    0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --whiteframe-6px:
    0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --whiteframe-7px:
    0px 4px 5px -2px rgba(0, 0, 0, 0.2),
    0px 7px 10px 1px rgba(0, 0, 0, 0.14),
    0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --whiteframe-8px:
    0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --whiteframe-9px:
    0px 5px 6px -3px rgba(0, 0, 0, 0.2),
    0px 9px 12px 1px rgba(0, 0, 0, 0.14),
    0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --whiteframe-10px:
    0px 6px 6px -3px rgba(0, 0, 0, 0.2),
    0px 10px 14px 1px rgba(0, 0, 0, 0.14),
    0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --whiteframe-11px:
    0px 6px 7px -4px rgba(0, 0, 0, 0.2),
    0px 11px 15px 1px rgba(0, 0, 0, 0.14),
    0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --whiteframe-12px:
    0px 7px 8px -4px rgba(0, 0, 0, 0.2),
    0px 12px 17px 2px rgba(0, 0, 0, 0.14),
    0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --whiteframe-13px:
    0px 7px 8px -4px rgba(0, 0, 0, 0.2),
    0px 13px 19px 2px rgba(0, 0, 0, 0.14),
    0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --whiteframe-14px:
    0px 7px 9px -4px rgba(0, 0, 0, 0.2),
    0px 14px 21px 2px rgba(0, 0, 0, 0.14),
    0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.whiteframe-1dp, .whiteframe-z1 { box-shadow: var(--whiteframe-1dp) }
.whiteframe-2dp { box-shadow: var(--whiteframe-2dp) }
.whiteframe-3dp { box-shadow: var(--whiteframe-3dp) }
.whiteframe-4dp, .whiteframe-z2 {  box-shadow: var(--whiteframe-4px) }
.whiteframe-5dp { box-shadow: var(--whiteframe-5px) }
.whiteframe-6dp { box-shadow: var(--whiteframe-6px) }
.whiteframe-7dp, .whiteframe-z3 { box-shadow: var(--whiteframe-7px) }
.whiteframe-8dp { box-shadow: var(--whiteframe-8px) }
.whiteframe-9dp { box-shadow: var(--whiteframe-9px) }
.whiteframe-10dp, .whiteframe-z4 { box-shadow: var(--whiteframe-10px) } 
.whiteframe-11dp { box-shadow: var(--whiteframe-11px) }
.whiteframe-12dp { box-shadow: var(--whiteframe-12px) }
.whiteframe-13dp, .whiteframe-z5 {  box-shadow: var(--whiteframe-13px) }
.whiteframe-14dp {  box-shadow: var(--whiteframe-14px) }
.whiteframe-15dp {  box-shadow: var(--whiteframe-15px) }
.whiteframe-16dp {  box-shadow: var(--whiteframe-16px) }
.whiteframe-17dp { box-shadow: var(--whiteframe-17px) }
.whiteframe-18dp { box-shadow: var(--whiteframe-18px) }
.whiteframe-19dp { box-shadow: var(--whiteframe-19px) }
.whiteframe-20dp { box-shadow: var(--whiteframe-20px) }
.whiteframe-21dp { box-shadow: var(--whiteframe-21px) }
.whiteframe-22dp { box-shadow: var(--whiteframe-22px) }
.whiteframe-23dp { box-shadow: var(--whiteframe-23px) }
.whiteframe-24dp { box-shadow: var(--whiteframe-24px) }
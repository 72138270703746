.chip {
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 18px;
  min-width: 32px;
  min-height: 32px;
  padding: 0px 12px;
  margin: 4px;
  border-radius: 18px;
  color: var(--color-default-87);
  background-color: var(--color-gray-300);
}

.chip.primary { color: white; background-color : var(--color-primary); }
.chip.accent { color: white; background-color : var(--color-accent); }
.chip.warn { color: white; background-color : var(--color-warn); }

.chip.button:active { box-shadow :var(--whiteframe-2dp); }
.chip.button.disabled, .chip.button.disabled:active { 
  box-shadow : none;
  color : rgba(0,0,0,0.24);
  cursor: default;
}

.chip .icon-button-container .icon-button {
  margin: 0 -12px 0 4px;
}
.multi-add-item {
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.multi-add-item:not(:last-of-type):not(:only-of-type) {
  border-bottom: 1px solid var(--color-default-12);
}
.multi-add-item__actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.multi-add-item .row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.multi-add-item_summary {
  margin-top: 8px;
  margin-bottom:8px;
  padding-left: 38px;
}

.multi-add-item .column {
  display: flex;
  flex-direction: column;
}

.multi-add-item_summary, .multi-add-item_edit {
  display: flex;
  flex-direction: row;
}

.multi-add-item__edit-line {
  margin-left: 34px;
}
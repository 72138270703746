body { font-family: "Roboto", "Helvetica", "Arial", sans-serif; }

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, Chrome and Opera */
}

.flexbox-row {
  display: flex;
  flex-direction: row;
}

.flexbox-row.margin-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}
.side-navigation {
  position: fixed;
  background-color: white;
  box-shadow: var(--whiteframe-6px);
  color: black;
  width: 320px;
  height: 100%;
  display: flex;
  flex-direction: column;
  top: 0px;
  left: -320px;
  border-radius: 2px;
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1);
  z-index: 100;
}
.side-navigation.shown {
  transform: translateX(320px);
}

.side-navigation__list {
  overflow-y: auto;
}
.side-navigation__list a {
  color: var(--color-default-87);
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 36px;
  padding: 0px 11px;
  text-decoration: none;
}

.side-navigation__list a:hover { background-color : rgba(158,158,158,.2); }
.side-navigation__list a:active { background-color :rgba(158,158,158,.4); }

.side-navigation__list i {
  max-width: 24px;
  margin: 0px 24px 0px 6px;
}
.side-navigation__list .avatar {
  margin: 0px 20px 0px 12px;
}
.side-navigation__large-area {
  min-height: 40px;
  padding: 16px 4px;
  box-shadow: var(--whiteframe-1dp);
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.005em;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  color: var(--color-default-87)
}

.side-navigation__list-item {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  padding: 0px 16px;
  min-height: 32px;
}
.side-navigation__list-item:hover{
  background-color: var(--color-default-12)
}
.side-navigation__list-item .right {
  margin-left: auto;
}
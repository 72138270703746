.rich-text-editor--container {
  flex: 1;
  overflow-y: none;
  overflow-x: none;
  padding: 8px;
  resize: none;
  outline: none;
  border: 0;
}

.rich-text-editor {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  white-space: pre-wrap;
  resize: none;
  outline: none;
  border: 0;
}


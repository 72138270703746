.input-container {
  padding: 9px 4px;
  margin: 0;
  min-height: 30px;
  min-width: 64px;
  display: flex;
  flex: 1;
  flex-direction: row;
  position: relative;
}
.input-container.has-label {
  padding: 16px 4px 9px 4px;
}
.text-input, .text-title {
  margin: auto 4px auto 0px;
  padding: 2px 2px 1px 2px;
  border-width: 0 0 0px 0;
  line-height: 26px;
  height: 30px;
  -ms-flex-preferred-size: 26px;
  border-style: solid;
  min-width: 64px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.010em;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  outline: none;
}
.text-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 10000px;
  display: block;
}
.text-input {
  -webkit-transition: background-color 350ms ease-in-out, border-bottom-color 100ms ease-in-out;
  -ms-transition: background-color 350ms ease-in-out, border-bottom-color 100ms ease-in-out;
  transition: background-color 350ms ease-in-out, border-bottom-color 100ms ease-in-out;
  background-color: rgba(0,0,0,0);
  border-bottom: 1px solid rgba(0,0,0,.0);
}
.text-input::placeholder { color: #00000061; }
.text-input::-webkit-input-placeholder { color: #00000061; }
.text-input::-moz-placeholder { color: #00000061; }

.text-input.full-width {
  flex: 1;
  width: 100%;
}
.colors-dark-background .text-input {
  background-color: rgba(255,255,255,.0);
  border-bottom: 1px solid rgba(255,255,255,.0); 
}
.colors-dark-background .text-input { 
  color : white; border-bottom: 1px solid rgba(255,255,255,.0);
}
.text-input:hover { 
  border-bottom: 1px solid rgba(0,0,0,.42); 
  background-color: rgba(0,0,0,.0348);
}
.colors-dark-background .text-input:hover { 
  background-color: rgba(255,255,255,.0348);
  border-bottom: 1px solid rgba(255,255,255,.42); 
}

.text-input:focus:active { 
  background-color: rgba(0,0,0,.09);
  border-bottom: 2px solid;
  border-bottom-color: var(--color-primary) }
.colors-dark-background .text-input:focus:active { 
  background-color: rgba(255,255,255,.09);
  border-bottom: 2px solid rgba(255,255,255,1); }

.text-input:focus:not(:active) { border-bottom: 2px solid; border-bottom-color: var(--color-primary-87) }
.colors-dark-background .text-input:focus:not(:active) { border-bottom: 2px solid rgba(255,255,255,.87); }

.colors-dark-background .text-input::placeholder { color: var(--color-white-default-56); }
.colors-dark-background .text-input::-webkit-input-placeholder { color: var(--color-white-default-56); }
.colors-dark-background .text-input::-moz-placeholder { color: var(--color-white-default-56); }

.text-input__label {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 6px;
  font-size: 16px;
  color: #00000061;
  transform : translateY(22px);
  transition: 
    transform .4s cubic-bezier(.25,.8,.25,1),
    font-size .4s cubic-bezier(.25,.8,.25,1),
    color .4s cubic-bezier(.25,.8,.25,1);
}
.input-container:focus .text-input__label, .text-input__label.floating {
  transform : translateY(0px);
  font-size: 12px;
  color: var(--color-primary);
}
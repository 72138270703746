.avatar {
  height: 40px;
  width: 40px;
  display: inline-block;
}
.avatar, .avatar-image, .avatar-placeholder, .avatar-placeholder-over{
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.avatar-placeholder-anchor {
  max-width: 0px;
  max-height: 0px;
  position: relative;
}

.avatar-placeholder, .avatar-placeholder-over {
  position: absolute;
  top: 0;
  left: 0;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.avatar-placeholder-over {
  z-index: 1;
}
.PopupScrim {
  position: absolute;
  z-index: 100;
  background-color: rgba(0,0,0,.32);
  left : 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

.popup-header, .popup-footer {
  display: flex;
  flex-direction: row;
  height: 56px;
  line-height: 56px;
  padding: 0px 8px;
}
.popup-sub-header {
  height: 32px;
  line-height: 32px;
  padding: 0px 24px;
  color: var(--color-default-87)
}
.popup-section {
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--color-default-12);
}
.popup-section:first-of-type {
  border-top: 1px solid var(--color-default-12);
}
.popup-section .row {
  display: flex;
  flex-direction: row;
}
.folder-option {
  cursor: pointer;
  height: 48px;
  line-height: 48px;
  display: flex;
  flex-direction: row;
}
.folder-option i {
  line-height: 48px;
  margin: auto 6px;
  padding: 0 16px;
}
.folder-option div {
  line-height: 48px;
  align-self: auto;
}
.folder-option:hover { background-color : rgba(158,158,158,.2); }
.folder-option:active { background-color :rgba(158,158,158,.4); }
.ui-test-pages {
  max-height: 100%;
  overflow-y: auto;
  flex: 1;
}
.uiTestPagesLinks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 16px;
}
.uiTestPagesLinks a {
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  margin: 8px;
  text-transform: uppercase;
  color: var(--color-primary);
}
.uiTestPage {
  width: 60%;
  margin: 8px auto;
  padding: 16px;
}
.uiTestPage.section, .UiTestSection {
  padding: 16px 0px;
}
.header, .UiTestSectionHeader {
  font-size: 16px;
  font-weight: 500;
  margin: 16px 0px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, .54);
}
.UiTestRowName {
  font-size: 14px;
  font-weight: 600;
  margin: 16px 0px 8px 0px;
  color: rgba(0, 0, 0, .84);
}
.UiTestRow {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  flex-wrap: wrap;
  border-radius: 4px;
}
.UiTestRow.colors-dark-background {
  background-color: var(--color-gray-900)
}
.UiTestRowItem {
  display: flex;
  margin: 0 8px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 0px;
}
.UiTestRowItemContent {
  display: flex;
  min-height: 64px;
  align-items: center;
  justify-items: center;
}
.UiTestRowItemName {
  font-size: 13px;
}
.UiTestRowItemName.failed {
  color: var(--color-warn)
}
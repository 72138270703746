.icon-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.icon-button {
  background-color: rgba(0,0,0,0);
  border: none;
  border-radius: 2px;
  height: 40px;
  width: 40px;
  line-height: 24px;
  margin: auto 6px;
  padding: 0 16px;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0;
  text-align: center;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  color : rgba(0,0,0,0.54);
  transition: background-color 500ms ease;
  -webkit-tap-highlight-color: transparent;
}
.icon-button:hover { background-color : rgba(158,158,158,.2); }
.icon-button:active { 
  background-color :rgba(158,158,158,.4); 
  transition: background-color 100ms ease;}
.icon-button.primary { color : var(--color-primary); }
.icon-button.accent { color : var(--color-accent); }
.icon-button.warn { color : var(--color-warn); }
.colors-dark-background .icon-button { color : rgba(255,255,255,0.7); }

.icon-button.disabled, .icon-button.disabled:active { 
  background-color: rgba(0,0,0,0);
  cursor: default;
}
.icon-button.small {
  height: 32px;
  width: 32px;
  padding: 7px;
}
.badge {
  border-radius: 50%;
  background-color: var(--color-primary);
  color: white;
  height: 22px;
  width: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: var(--color-default-87);
  background-color: var(--color-gray-300);
}
.badge__text {
  font-size: 12;
  letter-spacing: .02em;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
}

.badge.primary { color: white; background-color : var(--color-primary); }
.badge.accent { color: white; background-color : var(--color-accent); }
.badge.warn { color: white; background-color : var(--color-warn); }

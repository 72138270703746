.chats-list__chat {
  display: flex;
  flex-direction: row;
  padding: 8px;
}

.chats-list__chat__content {
  display: flex;
  flex-direction: column;
  flex: 1;
} 

.chats-list__chat__avatar {
  margin-right: 8px;
}
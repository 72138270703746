/* The Actual App, inside the HOC, router, and other garbage */ 
.AppContent {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden; 
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.icon-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.icon-button {
  background-color: rgba(0,0,0,0);
  border: none;
  border-radius: 2px;
  height: 40px;
  width: 40px;
  line-height: 24px;
  margin: auto 6px;
  padding: 0 16px;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0;
  text-align: center;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  color : rgba(0,0,0,0.54);
  transition: background-color 500ms ease;
  -webkit-tap-highlight-color: transparent;
}
.icon-button:hover { background-color : rgba(158,158,158,.2); }
.icon-button:active { 
  background-color :rgba(158,158,158,.4); 
  transition: background-color 100ms ease;}
.icon-button.primary { color : var(--color-primary); }
.icon-button.accent { color : var(--color-accent); }
.icon-button.warn { color : var(--color-warn); }
.colors-dark-background .icon-button { color : rgba(255,255,255,0.7); }

.icon-button.disabled, .icon-button.disabled:active { 
  background-color: rgba(0,0,0,0);
  cursor: default;
}
.icon-button.small {
  height: 32px;
  width: 32px;
  padding: 7px;
}
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/MaterialIcons-Regular.e79bfd88.eot); /* For IE6-8 */
  src: local('Material Icons'),
       local('MaterialIcons-Regular'),
       url(/static/media/MaterialIcons-Regular.570eb838.woff2) format('woff2'),
       url(/static/media/MaterialIcons-Regular.012cf6a1.woff) format('woff'),
       url(/static/media/MaterialIcons-Regular.a37b0c01.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  -webkit-font-feature-settings: 'liga';
          font-feature-settings: 'liga';
}

.material-icons.primary { color : var(--color-primary); }
.material-icons.accent { color : var(--color-accent); }
.material-icons.warn { color : var(--color-warn); }
.material-icons.small { font-size: 18px; }
.flat-button {
  background-color: rgba(0,0,0,0);
  border: none;
  border-radius: 2px;
  color: #000;
  height: 36px;
  margin: 6px 8px;
  min-width: 64px;
  padding: 0 16px;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0;
  text-align: center;
  line-height: 36px;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  justify-content: center;
  -webkit-tap-highlight-color: transparent;
}
.flat-button:hover { background-color : rgba(158,158,158,.2); }
.flat-button:active { background-color :rgba(158,158,158,.4); }

.flat-button.disabled:hover, .flat-button.disabled:active { 
  background : none;
  cursor: default; 
}

.flat-button.primary, .colors-dark-background .flat-button.primary { color : var(--color-primary); }
.flat-button.accent, .colors-dark-background .flat-button.accent { color : var(--color-accent); }
.flat-button.warn, .colors-dark-background .flat-button.warn { color : var(--color-warn); }

.colors-dark-background .flat-button { color : rgba(255,255,255,0.7); }
.raised-button {
  background-color: var(--color-gray-200);
  border: none;
  border-radius: 2px;
  color: #000;
  height: 36px;
  margin: 6px 8px;
  min-width: 64px;
  padding: 0 16px;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0;
  text-align: center;
  line-height: 36px;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
  box-shadow :var(--whiteframe-2dp);
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  justify-content: center;
  -webkit-tap-highlight-color: transparent;
}
/* .flat-button:hover { background-color : rgba(158,158,158,.2); } */
.raised-button:active { box-shadow :var(--whiteframe-8px); }

.raised-button.primary { color: white; background-color : var(--color-primary); }
.raised-button.accent { color: white; background-color : var(--color-accent); }
.raised-button.warn { color: white; background-color : var(--color-warn); }

.colors-dark-background .raised-button { color : rgba(255,255,255,0.7); }

.raised-button.disabled, .raised-button.disabled:active { 
  box-shadow : none; 
  color : rgba(0,0,0,0.24);
  cursor: default;
}
.floating-action-button {
  background-color: var(--color-gray-200);
  border: none;
  border-radius: 50%;
  color: #000;
  height: 56px;
  width: 56px;
  margin: 0;
  padding: 16px;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0;
  text-align: center;
  line-height: 36px;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
  box-shadow :var(--whiteframe-6px);
  -webkit-tap-highlight-color: transparent;
}
.floating-action-button.mini { height: 40px;  width: 40px; padding: 8px}
/* .flat-button:hover { background-color : rgba(158,158,158,.2); } */
.floating-action-button:active { box-shadow :var(--whiteframe-12px); }

/* .flat-button.mini { min-width: 36px; padding: 0} */
.floating-action-button.primary { color: white; background-color : var(--color-primary); }
.floating-action-button.accent { color: white; background-color : var(--color-accent); }
.floating-action-button.warn { color: white; background-color : var(--color-warn); }
.colors-dark-background .floating-action-button { color : rgba(255,255,255,0.7); }

.floating-action-button.disabled, .floating-action-button.disabled:active {
  box-shadow : none;
  color : rgba(0,0,0,0.24);
  cursor: default;
}
.ui-test-pages {
  max-height: 100%;
  overflow-y: auto;
  flex: 1 1;
}
.uiTestPagesLinks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 16px;
}
.uiTestPagesLinks a {
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  margin: 8px;
  text-transform: uppercase;
  color: var(--color-primary);
}
.uiTestPage {
  width: 60%;
  margin: 8px auto;
  padding: 16px;
}
.uiTestPage.section, .UiTestSection {
  padding: 16px 0px;
}
.header, .UiTestSectionHeader {
  font-size: 16px;
  font-weight: 500;
  margin: 16px 0px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, .54);
}
.UiTestRowName {
  font-size: 14px;
  font-weight: 600;
  margin: 16px 0px 8px 0px;
  color: rgba(0, 0, 0, .84);
}
.UiTestRow {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  flex-wrap: wrap;
  border-radius: 4px;
}
.UiTestRow.colors-dark-background {
  background-color: var(--color-gray-900)
}
.UiTestRowItem {
  display: flex;
  margin: 0 8px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 0px;
}
.UiTestRowItemContent {
  display: flex;
  min-height: 64px;
  align-items: center;
  justify-items: center;
}
.UiTestRowItemName {
  font-size: 13px;
}
.UiTestRowItemName.failed {
  color: var(--color-warn)
}
.card {
  box-shadow: var(--whiteframe-2dp);
  border-radius: 4px;
  background-color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.card-title {
  padding: 16px;
  display: flex;
  flex-direction: row;
}
.card-content {
  padding: 16px;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow: hidden;
}
.card-actions {
  border-top: solid 1px var(--color-default-12);
  display: flex;
  flex-direction: row;
}
.typography-h1, .typography-h2, .typography-h3, .typography-h4, .typography-h5, .typography-h6 {
  margin-bottom: 1rem; margin-top: 1rem;
}
.typography-h4 { font-size: 112px; letter-spacing: -1.12px; font-weight: 300; line-height: 112px; }
.typography-h3 { font-size: 56px; font-weight: 400; letter-spacing: -.005em; line-height: 56px; }
.typography-h2 { font-size: 45px; font-weight: 400; line-height: 64px; }
.typography-h1 { font-size: 34px; font-weight: 400; line-height: 40px; }
.typography-headline { font-size: 24px; font-weight: 400; line-height: 32px;}
.typography-title { font-size: 20px; font-weight: 500; line-height: 28px; letter-spacing: .005em;}
.typography-subtitle { font-size: 16px; letter-spacing: .0em; font-weight: 400; line-height: 24px; }
.typography-subtitle2 { font-size: 16px; letter-spacing: .0em; font-weight: 500; line-height: 24px; }
.typography-body, .typography-body-medium, .typography-body-light { font-size: 14px; letter-spacing: .01em; font-weight: 400; line-height: 20px; }
.typography-body-light { color: var(--color-default-56)}
.typography-body-medium { color: var(--color-default-87)}
.typography-body-bold { font-size: 14px; letter-spacing: .01em; font-weight: 500; line-height: 24px}
.typography-caption { font-size: 12px; letter-spacing: .02em; line-height: 16.8px; color: var(--color-default-87)}
.typography-caption-light { font-size: 12px; letter-spacing: .02em; line-height: 16.8px; color: var(--color-default-56)}
.alpha-87 { color: var(--color-default-87)}

.colors-dark-background .typography-body-medium, .colors-dark-background .alpha-87, .colors-dark-background .typography-caption {
   color: var(--color-white-default-87)
}
.colors-dark-background .typography-body-light, .colors-dark-background .typography-caption-light {
  color: var(--color-white-default-56)
}
.PopupScrim {
  position: absolute;
  z-index: 100;
  background-color: rgba(0,0,0,.32);
  left : 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

.popup-header, .popup-footer {
  display: flex;
  flex-direction: row;
  height: 56px;
  line-height: 56px;
  padding: 0px 8px;
}
.popup-sub-header {
  height: 32px;
  line-height: 32px;
  padding: 0px 24px;
  color: var(--color-default-87)
}
.popup-section {
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--color-default-12);
}
.popup-section:first-of-type {
  border-top: 1px solid var(--color-default-12);
}
.popup-section .row {
  display: flex;
  flex-direction: row;
}
/*
  Modal Bottom/Side Sheet, Top Sheet, Side Sheet   16dp
  Side Nav  16dp
  Normal Bottom/Side Sheet, Top Sheet, Side Sheet   12dp
  Dialog    24dp
  Nav Bar, App Bar, Header Bar    8dp
  Top App Bar   4dp
  Sub App Bar 2dp
  FAB   6dp, 12dp
  Loading Indicator 3dp
  Menus   6dp
  Raised Button   2dp, 8dp 
  Card  1dp   Lifted: 8dp 
  Search Bar    1dp
*/

/* https://material-component.github.io/material-components-web-catalaog/#/component/elevation*/
:root {
  --whiteframe-1dp: 
    0px 2px 1px -1px rgba(0, 0, 0, 0.2), 
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --whiteframe-2dp: 
    0px 3px 1px -2px rgba(0, 0, 0, 0.2), 
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --whiteframe-3dp:
    0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14),
    0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --whiteframe-4px:
    0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --whiteframe-5px:
    0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14),
    0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --whiteframe-6px:
    0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --whiteframe-7px:
    0px 4px 5px -2px rgba(0, 0, 0, 0.2),
    0px 7px 10px 1px rgba(0, 0, 0, 0.14),
    0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --whiteframe-8px:
    0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --whiteframe-9px:
    0px 5px 6px -3px rgba(0, 0, 0, 0.2),
    0px 9px 12px 1px rgba(0, 0, 0, 0.14),
    0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --whiteframe-10px:
    0px 6px 6px -3px rgba(0, 0, 0, 0.2),
    0px 10px 14px 1px rgba(0, 0, 0, 0.14),
    0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --whiteframe-11px:
    0px 6px 7px -4px rgba(0, 0, 0, 0.2),
    0px 11px 15px 1px rgba(0, 0, 0, 0.14),
    0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --whiteframe-12px:
    0px 7px 8px -4px rgba(0, 0, 0, 0.2),
    0px 12px 17px 2px rgba(0, 0, 0, 0.14),
    0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --whiteframe-13px:
    0px 7px 8px -4px rgba(0, 0, 0, 0.2),
    0px 13px 19px 2px rgba(0, 0, 0, 0.14),
    0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --whiteframe-14px:
    0px 7px 9px -4px rgba(0, 0, 0, 0.2),
    0px 14px 21px 2px rgba(0, 0, 0, 0.14),
    0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.whiteframe-1dp, .whiteframe-z1 { box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12); box-shadow: var(--whiteframe-1dp) }
.whiteframe-2dp { box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12); box-shadow: var(--whiteframe-2dp) }
.whiteframe-3dp { box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14),
    0px 1px 8px 0px rgba(0, 0, 0, 0.12); box-shadow: var(--whiteframe-3dp) }
.whiteframe-4dp, .whiteframe-z2 {  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);  box-shadow: var(--whiteframe-4px) }
.whiteframe-5dp { box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14),
    0px 1px 14px 0px rgba(0, 0, 0, 0.12); box-shadow: var(--whiteframe-5px) }
.whiteframe-6dp { box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12); box-shadow: var(--whiteframe-6px) }
.whiteframe-7dp, .whiteframe-z3 { box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2),
    0px 7px 10px 1px rgba(0, 0, 0, 0.14),
    0px 2px 16px 1px rgba(0, 0, 0, 0.12); box-shadow: var(--whiteframe-7px) }
.whiteframe-8dp { box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12); box-shadow: var(--whiteframe-8px) }
.whiteframe-9dp { box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2),
    0px 9px 12px 1px rgba(0, 0, 0, 0.14),
    0px 3px 16px 2px rgba(0, 0, 0, 0.12); box-shadow: var(--whiteframe-9px) }
.whiteframe-10dp, .whiteframe-z4 { box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2),
    0px 10px 14px 1px rgba(0, 0, 0, 0.14),
    0px 4px 18px 3px rgba(0, 0, 0, 0.12); box-shadow: var(--whiteframe-10px) } 
.whiteframe-11dp { box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2),
    0px 11px 15px 1px rgba(0, 0, 0, 0.14),
    0px 4px 20px 3px rgba(0, 0, 0, 0.12); box-shadow: var(--whiteframe-11px) }
.whiteframe-12dp { box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
    0px 12px 17px 2px rgba(0, 0, 0, 0.14),
    0px 5px 22px 4px rgba(0, 0, 0, 0.12); box-shadow: var(--whiteframe-12px) }
.whiteframe-13dp, .whiteframe-z5 {  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
    0px 13px 19px 2px rgba(0, 0, 0, 0.14),
    0px 5px 24px 4px rgba(0, 0, 0, 0.12);  box-shadow: var(--whiteframe-13px) }
.whiteframe-14dp {  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2),
    0px 14px 21px 2px rgba(0, 0, 0, 0.14),
    0px 5px 26px 4px rgba(0, 0, 0, 0.12);  box-shadow: var(--whiteframe-14px) }
.whiteframe-15dp {  box-shadow: var(--whiteframe-15px) }
.whiteframe-16dp {  box-shadow: var(--whiteframe-16px) }
.whiteframe-17dp { box-shadow: var(--whiteframe-17px) }
.whiteframe-18dp { box-shadow: var(--whiteframe-18px) }
.whiteframe-19dp { box-shadow: var(--whiteframe-19px) }
.whiteframe-20dp { box-shadow: var(--whiteframe-20px) }
.whiteframe-21dp { box-shadow: var(--whiteframe-21px) }
.whiteframe-22dp { box-shadow: var(--whiteframe-22px) }
.whiteframe-23dp { box-shadow: var(--whiteframe-23px) }
.whiteframe-24dp { box-shadow: var(--whiteframe-24px) }
.time-popup-header {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 60px;
  min-height : 64px;
  font-weight: 300;
}

.popup-panel .header h1 {
  margin : 0px;
}
.popup-panel .body {
  flex : 1 1;
  padding : 16px;
}
.clock-circle-holder {
  display: flex;
  flex-direction: column;
}
.clock-circle {
  border-radius: 50%;
  width: 256px;
  height: 256px;
  background-color: #EEEEEE;
  align-self: center;
  vertical-align: center;
  position: relative;
}
.clock-circle-dot {
  position: absolute;
  border-radius: 50%; 
  height: 6px; width: 6px;
  margin: auto;
  left: 0; right: 0; top : 0; bottom: 0;
  background-color: #607d8b;
}
.clock-circle-hand {
  position: absolute;
  width: 256px; height: 256;
}
.time-circle {
  z-index: 0;
  position: absolute;
  width : 32px;
  height : 32px;
  border-radius : 50%;
  text-align : center;
  line-height : 32px;
}
.click-target {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 8px;
  left: 8px;
  border-radius: 50%;
}
.time-circle-number {
  z-index: 1;
}
.time-circle-dot, .time-circle-dot-hidden {
  margin: 15px;
  border-radius: 50%; 
  height: 4px; width: 4px;
}
.time-circle-dot { background-color: white; }
.time-circle-dot-hidden { background-color: none; }

.clock-right-container {
  flex:1 1;
  flex-direction: row;
  display: flex;
  align-items: center;
}
.clock-am-pm-container {
  display: flex;
  flex-direction: column;
  margin: 6px 8px;
}

.clock-time { color: rgba(255,255,255,.54); }
.clock-time:hover { color: rgba(255,255,255,.71); }
.clock-time:active { color: rgba(255,255,255,.87); }
.clock-time-selected { color: rgba(255,255,255,.87); }
.clock-time-selected:active { color: rgba(255,255,255,1);}

/* Override specific to time picker */
.clock-am-pm-container .flat-button {
  height: 24px; 
  line-height: 24px
}
/*https://material.io/design/color/the-color-system.html#*/
:root {
  --color-clear: #00000000;

  --color-primary: #607d8b;
  --color-primary-87: #607d8bDE;
  --color-primary-56: #607d8b8f;
  
  --color-accent: #FFAB00;
  --color-accent-87: #FFAB00DE;

  --color-warn : #F44336;
  --color-warn-87: #F44336DE;

  --color-default: #000000;
  --color-default-87: #000000DE;
  --color-default-56: #0000008f;
  --color-default-38: #00000061;
  --color-default-26: #00000042;
  --color-default-12: #0000001f;

  --color-white-default: #ffffff;
  --color-white-default-87: #ffffffDE;
  --color-white-default-56: #ffffff8f;
  --color-white-default-38: #ffffff61;
  --color-white-default-26: #ffffff42;
  --color-white-default-12: #ffffff1f;

  --color-red-50 : #FFEbEE;
  --color-red-100 : #FFcDD2;
  --color-red-200 : #EF9a9a;
  --color-red-300 : #E57373;
  --color-red-400 : #EF5350;
  --color-red-500 : #F44336;
  --color-red-600 : #E53935;
  --color-red-700 : #D32F2F;
  --color-red-800 : #c62828;
  --color-red-900 : #b71c1c;
  --color-red-a100 : #FF8a80;
  --color-red-a200 : #FF5252;
  --color-red-a400 : #FF1744;
  --color-red-a700 : #D50000;
  
  --color-pink-50 : #FcE4Ec;
  --color-pink-100 : #F8bbD0;
  --color-pink-200 : #F48Fb1;
  --color-pink-300 : #F06292;
  --color-pink-400 : #Ec407a;
  --color-pink-500 : #E91E63;
  --color-pink-600 : #D81b60;
  --color-pink-700 : #c2185b;
  --color-pink-800 : #aD1457;
  --color-pink-900 : #880E4F;
  --color-pink-a100 : #FF80ab;
  --color-pink-a200 : #FF4081;
  --color-pink-a400 : #F50057;
  --color-pink-a700 : #c51162;
  
  --color-purple-50 : #F3E5F5;
  --color-purple-100 : #E1bEE7;
  --color-purple-200 : #cE93D8;
  --color-purple-300 : #ba68c8;
  --color-purple-400 : #ab47bc;
  --color-purple-500 : #9c27b0;
  --color-purple-600 : #8E24aa;
  --color-purple-700 : #7b1Fa2;
  --color-purple-800 : #6a1b9a;
  --color-purple-900 : #4a148c;
  --color-purple-a100 : #Ea80Fc;
  --color-purple-a200 : #E040Fb;
  --color-purple-a400 : #D500F9;
  --color-purple-a700 : #aa00FF;
  
  --color-deep-purple-50 : #EDE7F6;
  --color-deep-purple-100 : #D1c4E9;
  --color-deep-purple-200 : #b39DDb;
  --color-deep-purple-300 : #9575cD;
  --color-deep-purple-400 : #7E57c2;
  --color-deep-purple-500 : #673ab7;
  --color-deep-purple-600 : #5E35b1;
  --color-deep-purple-700 : #512Da8;
  --color-deep-purple-800 : #4527a0;
  --color-deep-purple-900 : #311b92;
  --color-deep-purple-a100 : #b388FF;
  --color-deep-purple-a200 : #7c4DFF;
  --color-deep-purple-a400 : #651FFF;
  --color-deep-purple-a700 : #6200Ea;
  
  --color-indigo-50 : #E8EaF6;
  --color-indigo-100 : #c5caE9;
  --color-indigo-200 : #9Fa8Da;
  --color-indigo-300 : #7986cb;
  --color-indigo-400 : #5c6bc0;
  --color-indigo-500 : #3F51b5;
  --color-indigo-600 : #3949ab;
  --color-indigo-700 : #303F9F;
  --color-indigo-800 : #283593;
  --color-indigo-900 : #1a237E;
  --color-indigo-a100 : #8c9EFF;
  --color-indigo-a200 : #536DFE;
  --color-indigo-a400 : #3D5aFE;
  --color-indigo-a700 : #304FFE;
  
  --color-blue-50 : #E3F2FD;
  --color-blue-100 : #bbDEFb;
  --color-blue-200 : #90caF9;
  --color-blue-300 : #64b5F6;
  --color-blue-400 : #42a5F5;
  --color-blue-500 : #2196F3;
  --color-blue-600 : #1E88E5;
  --color-blue-700 : #1976D2;
  --color-blue-800 : #1565c0;
  --color-blue-900 : #0D47a1;
  --color-blue-a100 : #82b1FF;
  --color-blue-a200 : #448aFF;
  --color-blue-a400 : #2979FF;
  --color-blue-a700 : #2962FF;
  
  --color-light-blue-50 : #E1F5FE;
  --color-light-blue-100 : #b3E5Fc;
  --color-light-blue-200 : #81D4Fa;
  --color-light-blue-300 : #4Fc3F7;
  --color-light-blue-400 : #29b6F6;
  --color-light-blue-500 : #03a9F4;
  --color-light-blue-600 : #039bE5;
  --color-light-blue-700 : #0288D1;
  --color-light-blue-800 : #0277bD;
  --color-light-blue-900 : #01579b;
  --color-light-blue-a100 : #80D8FF;
  --color-light-blue-a200 : #40c4FF;
  --color-light-blue-a400 : #00b0FF;
  --color-light-blue-a700 : #0091Ea;
  
  --color-cyan-50 : #E0F7Fa;
  --color-cyan-100 : #b2EbF2;
  --color-cyan-200 : #80DEEa;
  --color-cyan-300 : #4DD0E1;
  --color-cyan-400 : #26c6Da;
  --color-cyan-500 : #00bcD4;
  --color-cyan-600 : #00acc1;
  --color-cyan-700 : #0097a7;
  --color-cyan-800 : #00838F;
  --color-cyan-900 : #006064;
  --color-cyan-a100 : #84FFFF;
  --color-cyan-a200 : #18FFFF;
  --color-cyan-a400 : #00E5FF;
  --color-cyan-a700 : #00b8D4;
  
  --color-teal-50 : #E0F2F1;
  --color-teal-100 : #b2DFDb;
  --color-teal-200 : #80cbc4;
  --color-teal-300 : #4Db6ac;
  --color-teal-400 : #26a69a;
  --color-teal-500 : #009688;
  --color-teal-600 : #00897b;
  --color-teal-700 : #00796b;
  --color-teal-800 : #00695c;
  --color-teal-900 : #004D40;
  --color-teal-a100 : #a7FFEb;
  --color-teal-a200 : #64FFDa;
  --color-teal-a400 : #1DE9b6;
  --color-teal-a700 : #00bFa5;
  
  --color-green-50 : #E8F5E9;
  --color-green-100 : #c8E6c9;
  --color-green-200 : #a5D6a7;
  --color-green-300 : #81c784;
  --color-green-400 : #66bb6a;
  --color-green-500 : #4caF50;
  --color-green-600 : #43a047;
  --color-green-700 : #388E3c;
  --color-green-800 : #2E7D32;
  --color-green-900 : #1b5E20;
  --color-green-a100 : #b9F6ca;
  --color-green-a200 : #69F0aE;
  --color-green-a400 : #00E676;
  --color-green-a700 : #00c853;
  
  --color-light-green-50 : #F1F8E9;
  --color-light-green-100 : #DcEDc8;
  --color-light-green-200 : #c5E1a5;
  --color-light-green-300 : #aED581;
  --color-light-green-400 : #9ccc65;
  --color-light-green-500 : #8bc34a;
  --color-light-green-600 : #7cb342;
  --color-light-green-700 : #689F38;
  --color-light-green-800 : #558b2F;
  --color-light-green-900 : #33691E;
  --color-light-green-a100 : #ccFF90;
  --color-light-green-a200 : #b2FF59;
  --color-light-green-a400 : #76FF03;
  --color-light-green-a700 : #64DD17;
  
  --color-lime-50 : #F9FbE7;
  --color-lime-100 : #F0F4c3;
  --color-lime-200 : #E6EE9c;
  --color-lime-300 : #DcE775;
  --color-lime-400 : #D4E157;
  --color-lime-500 : #cDDc39;
  --color-lime-600 : #c0ca33;
  --color-lime-700 : #aFb42b;
  --color-lime-800 : #9E9D24;
  --color-lime-900 : #827717;
  --color-lime-a100 : #F4FF81;
  --color-lime-a200 : #EEFF41;
  --color-lime-a400 : #c6FF00;
  --color-lime-a700 : #aEEa00;
  
  --color-yellow-50 : #FFFDE7;
  --color-yellow-100 : #FFF9c4;
  --color-yellow-200 : #FFF59D;
  --color-yellow-300 : #FFF176;
  --color-yellow-400 : #FFEE58;
  --color-yellow-500 : #FFEb3b;
  --color-yellow-600 : #FDD835;
  --color-yellow-700 : #Fbc02D;
  --color-yellow-800 : #F9a825;
  --color-yellow-900 : #F57F17;
  --color-yellow-a100 : #FFFF8D;
  --color-yellow-a200 : #FFFF00;
  --color-yellow-a400 : #FFEa00;
  --color-yellow-a700 : #FFD600;
  
  --color-amber-50 : #FFF8E1;
  --color-amber-100 : #FFEcb3;
  --color-amber-200 : #FFE082;
  --color-amber-300 : #FFD54F;
  --color-amber-400 : #FFca28;
  --color-amber-500 : #FFc107;
  --color-amber-600 : #FFb300;
  --color-amber-700 : #FFa000;
  --color-amber-800 : #FF8F00;
  --color-amber-900 : #FF6F00;
  --color-amber-a100 : #FFE57F;
  --color-amber-a200 : #FFD740;
  --color-amber-a400 : #FFc400;
  --color-amber-a700 : #FFab00;
  
  --color-orange-50 : #FFF3E0;
  --color-orange-100 : #FFE0b2;
  --color-orange-200 : #FFcc80;
  --color-orange-300 : #FFb74D;
  --color-orange-400 : #FFa726;
  --color-orange-500 : #FF9800;
  --color-orange-600 : #Fb8c00;
  --color-orange-700 : #F57c00;
  --color-orange-800 : #EF6c00;
  --color-orange-900 : #E65100;
  --color-orange-a100 : #FFD180;
  --color-orange-a200 : #FFab40;
  --color-orange-a400 : #FF9100;
  --color-orange-a700 : #FF6D00;
  
  --color-deep-orange-50 : #FbE9E7;
  --color-deep-orange-100 : #FFccbc;
  --color-deep-orange-200 : #FFab91;
  --color-deep-orange-300 : #FF8a65;
  --color-deep-orange-400 : #FF7043;
  --color-deep-orange-500 : #FF5722;
  --color-deep-orange-600 : #F4511E;
  --color-deep-orange-700 : #E64a19;
  --color-deep-orange-800 : #D84315;
  --color-deep-orange-900 : #bF360c;
  --color-deep-orange-a100 : #FF9E80;
  --color-deep-orange-a200 : #FF6E40;
  --color-deep-orange-a400 : #FF3D00;
  --color-deep-orange-a700 : #DD2c00;
  
  --color-brown-50 : #EFEbE9;
  --color-brown-100 : #D7ccc8;
  --color-brown-200 : #bcaaa4;
  --color-brown-300 : #a1887F;
  --color-brown-400 : #8D6E63;
  --color-brown-500 : #795548;
  --color-brown-600 : #6D4c41;
  --color-brown-700 : #5D4037;
  --color-brown-800 : #4E342E;
  --color-brown-900 : #3E2723;

  --color-gray-50 : #FAFAFA;
  --color-gray-100 : #F5F5F5;
  --color-gray-200 : #EEEEEE;
  --color-gray-300 : #E0E0E0;
  --color-gray-400 : #BDBDBD;
  --color-gray-500 : #9E9E9E;
  --color-gray-600 : #757575;
  --color-gray-700 : #616161;
  --color-gray-800 : #424242;
  --color-gray-900 : #212121;
    
  --color-blue-gray-50 : #EcEFF1;
  --color-blue-gray-100 : #cFD8Dc;
  --color-blue-gray-200 : #b0bEc5;
  --color-blue-gray-300 : #90a4aE;
  --color-blue-gray-400 : #78909c;
  --color-blue-gray-500 : #607D8b;
  --color-blue-gray-600 : #546E7a;
  --color-blue-gray-700 : #455a64;
  --color-blue-gray-800 : #37474F;
  --color-blue-gray-900 : #263238;
}

.colors-fill-primary {
  background-color: #607d8b;
  color : #FFF
}

.colors-primary { color : #607d8b; color : var(--color-primary) }
.colors-accent { color : #FFAB00; color : var(--color-accent) }
.colors-warn { color : #F44336; color : var(--color-warn) }
.date-panel {
  position: fixed;
  top: 0;
  left: 0;
}
.date-panel__week, .day-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.month-header {
  margin: 4px 0;
  height: 48px;
  display: flex;
  flex-direction: row;
}
.month-header__month {
  font-size: 16px;
  color: var(--color-default-87);
  text-align: center;
  flex: 1 1;
  align-self: center;
}

.day-header__day, .date-panel__week .day, .date-panel__week .day--empty, .date-panel__week .day--selected {
  min-width: 36px;
  min-height: 36px;
  text-align: center;
  vertical-align: center;
  line-height: 36px;
  border-radius: 50%;
  font-size: 14px;
  margin: 0 2px;
}
.day-header__day {
  font-size: 12px;
  color: var(--color-default-38)
}
.date-panel__week .day:hover {
  background-color: var(--color-default-12);
}

.date-panel__week .day--selected {
  background-color: var(--color-primary);
  color : white;
}
.popup-panel.standard-width {
  min-width: 60%;
}

.popup-panel {
  display : flex;
  flex-flow : column;
  pointer-events: auto; 
  background-color : white;
  margin: auto;
  border-radius: 4px;
}

.popup-panel-container {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  pointer-events: none;
  display : flex;
  z-index: 100;
}
.triangle-down {
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
    border-right: 5px solid transparent;
	border-top: 5px solid #000;
}
.menu {
  margin: 8px 6px;
}
.menu-contents {
  background-color: white;
  width: 100%;
  overflow-y: auto;
  padding: 16px 0px;
}
.hidden .menu-contents {
  display: none;
}
.menu-sizing {
  position: fixed;
  padding: 16px 0px;
  left: 0px;
  bottom: 100vmax;
}
.menu-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 16px;
  height: 48px;
  font-size: 15px;
}
.menu-item.selected, .menu-item:hover { background-color : rgb(238,238,238); }
.menu-item:active { background-color :rgb(199,199,199); }
.menu-item.disabled:hover, .menu-item.disabled:active { 
  background : none;
  cursor: default; 
}
.menu-item.primary, .colors-dark-background .menu-item.primary { color : var(--color-primary); }
.menu-item.accent, .colors-dark-background .menu-item.accent { color : var(--color-accent); }
.menu-item.warn, .colors-dark-background .menu-item.warn { color : var(--color-warn); }
.select-picker {
  flex: 1 1
}

.select-picker-menu {
  background-color: white;
  width: 100%;
  z-index: 100;
  overflow-y: auto;
}

.select-picker-menu.hidden {
  display: none;
}
.select-picker-menu-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 2px 16px;
  height: 48px;
  font-size: 16px;
}
.select-picker-menu-item .text, .select-picker-menu-item .placeholder-text {
  flex : 100% 1;
}
.select-picker-menu-item .placeholder-text {
  color: var(--color-default-38);
  margin-left: -12px;
}
.select-picker-menu-item .selected {
  color: rgb(96,125,139) 
}

.select-picker-menu-item .text {
  line-height: 24px;
  margin-left: 4px;
  display: inline-block;
}
.color-picker {
  flex: 1 1
}
.color-picker-menu {
  background-color: white;
  width: 100%;
  z-index: 100;
  overflow-y: auto;
}
.color-picker-menu.hidden {
  display: none;
}
.color-picker-menu-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 2px 16px 2px 0px;
  height: 48px;
  font-size: 16px;
}
.color-picker-menu-item .text, .color-picker-menu-item .placeholder-text {
  flex : 100% 1;
}
.color-picker-menu-item .placeholder-text {
  color: var(--color-default-38);
  margin-left: -12px;
}
.color-picker-menu-item .selected {
  color: rgb(96,125,139) 
}

.color-picker-menu-item .text {
  line-height: 24px;
  margin-left: 4px;
  display: inline-block;
}
.input-container {
  padding: 9px 4px;
  margin: 0;
  min-height: 30px;
  min-width: 64px;
  display: flex;
  flex: 1 1;
  flex-direction: row;
  position: relative;
}
.input-container.has-label {
  padding: 16px 4px 9px 4px;
}
.text-input, .text-title {
  margin: auto 4px auto 0px;
  padding: 2px 2px 1px 2px;
  border-width: 0 0 0px 0;
  line-height: 26px;
  height: 30px;
  -ms-flex-preferred-size: 26px;
  border-style: solid;
  min-width: 64px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.010em;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  outline: none;
}
.text-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 10000px;
  display: block;
}
.text-input {
  transition: background-color 350ms ease-in-out, border-bottom-color 100ms ease-in-out;
  background-color: rgba(0,0,0,0);
  border-bottom: 1px solid rgba(0,0,0,.0);
}
.text-input::-webkit-input-placeholder { color: #00000061; }
.text-input::-ms-input-placeholder { color: #00000061; }
.text-input::placeholder { color: #00000061; }
.text-input::-webkit-input-placeholder { color: #00000061; }
.text-input::-moz-placeholder { color: #00000061; }

.text-input.full-width {
  flex: 1 1;
  width: 100%;
}
.colors-dark-background .text-input {
  background-color: rgba(255,255,255,.0);
  border-bottom: 1px solid rgba(255,255,255,.0); 
}
.colors-dark-background .text-input { 
  color : white; border-bottom: 1px solid rgba(255,255,255,.0);
}
.text-input:hover { 
  border-bottom: 1px solid rgba(0,0,0,.42); 
  background-color: rgba(0,0,0,.0348);
}
.colors-dark-background .text-input:hover { 
  background-color: rgba(255,255,255,.0348);
  border-bottom: 1px solid rgba(255,255,255,.42); 
}

.text-input:focus:active { 
  background-color: rgba(0,0,0,.09);
  border-bottom: 2px solid;
  border-bottom-color: var(--color-primary) }
.colors-dark-background .text-input:focus:active { 
  background-color: rgba(255,255,255,.09);
  border-bottom: 2px solid rgba(255,255,255,1); }

.text-input:focus:not(:active) { border-bottom: 2px solid; border-bottom-color: var(--color-primary-87) }
.colors-dark-background .text-input:focus:not(:active) { border-bottom: 2px solid rgba(255,255,255,.87); }

.colors-dark-background .text-input::-webkit-input-placeholder { color: var(--color-white-default-56); }

.colors-dark-background .text-input::-ms-input-placeholder { color: var(--color-white-default-56); }

.colors-dark-background .text-input::placeholder { color: var(--color-white-default-56); }
.colors-dark-background .text-input::-webkit-input-placeholder { color: var(--color-white-default-56); }
.colors-dark-background .text-input::-moz-placeholder { color: var(--color-white-default-56); }

.text-input__label {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 6px;
  font-size: 16px;
  color: #00000061;
  -webkit-transform : translateY(22px);
          transform : translateY(22px);
  transition: 
    font-size .4s cubic-bezier(.25,.8,.25,1),
    color .4s cubic-bezier(.25,.8,.25,1),
    -webkit-transform .4s cubic-bezier(.25,.8,.25,1);
  transition: 
    transform .4s cubic-bezier(.25,.8,.25,1),
    font-size .4s cubic-bezier(.25,.8,.25,1),
    color .4s cubic-bezier(.25,.8,.25,1);
  transition: 
    transform .4s cubic-bezier(.25,.8,.25,1),
    font-size .4s cubic-bezier(.25,.8,.25,1),
    color .4s cubic-bezier(.25,.8,.25,1),
    -webkit-transform .4s cubic-bezier(.25,.8,.25,1);
}
.input-container:focus .text-input__label, .text-input__label.floating {
  -webkit-transform : translateY(0px);
          transform : translateY(0px);
  font-size: 12px;
  color: var(--color-primary);
}
.date-picker {
  display: flex;
  flex-direction: row;
}
.app-screen {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  min-height: 256px;
  position: relative;
  overflow: hidden;
}
.flex {
  flex : 1 1;
}
div.column {
  display: flex;
  flex-direction: column;
}
.scrollable-column {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  max-height: 100%
}
div.row {
  display: flex;
  flex-direction: row;
}
div.wrap-row{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
div.centerItems{
  display: flex;
  justify-items: center;
  justify-content: center
}
div.rightItems{
  display: flex;
  justify-items: flex-end;
  justify-content: flex-end
}
.screen-header {
  min-height: 56px;
  max-height: 56px;
  display: flex;
  flex: 1 1;
  flex-direction: row;
  z-index: 3;
}
.screen-sub-header {
  height: 40px;
  box-shadow: var(--whiteframe-2dp);
  display: flex;
  flex-direction: row;
  z-index: 2;
  align-items: center;
  background-color: var(--color-gray-50);
}
.screen-sub-header.toggleable {
  margin-top: -40px;
  transition: -webkit-transform 225ms cubic-bezier(0, 0, 0.2, 1);
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1);
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1), -webkit-transform 225ms cubic-bezier(0, 0, 0.2, 1);
  -webkit-transform: translateY(40px);
          transform: translateY(40px)
}
.screen-sub-header.hidden {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
}
.screen-header.primary {
  background-color: var(--color-primary);
}
.colors-dark-background {
  color: white;
}
.screenBody {
  overflow: none;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}
.screenFooter {
  min-height: 56px;
  max-height: 56px;
  display: flex;
  flex: 1 1;
  flex-direction: row;
  overflow-y: none;
  overflow-x: auto;
  border-top: 1px solid rgba(0, 0, 0, 0.12)
}
.section-right {
  align-self: flex-end;
}
.screen-fab-area {
  position: relative; 
  width: 100%;
  max-height: 1px;
}
.screen-fab-area .floating-action-button {
  position: absolute;
  bottom: 20px;
  right: 16px; 
}
.toast {
  background-color: black;
  color: white;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: -48px;
  border-radius: 2px;
  padding: 6px 24px;
  transition: margin-bottom 225ms cubic-bezier(0, 0, 0.2, 1);
}
.toast__actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.toast.shown {
  margin-bottom: 0px;
}
.side-navigation {
  position: fixed;
  background-color: white;
  box-shadow: var(--whiteframe-6px);
  color: black;
  width: 320px;
  height: 100%;
  display: flex;
  flex-direction: column;
  top: 0px;
  left: -320px;
  border-radius: 2px;
  transition: -webkit-transform 225ms cubic-bezier(0, 0, 0.2, 1);
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1);
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1), -webkit-transform 225ms cubic-bezier(0, 0, 0.2, 1);
  z-index: 100;
}
.side-navigation.shown {
  -webkit-transform: translateX(320px);
          transform: translateX(320px);
}

.side-navigation__list {
  overflow-y: auto;
}
.side-navigation__list a {
  color: var(--color-default-87);
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 36px;
  padding: 0px 11px;
  text-decoration: none;
}

.side-navigation__list a:hover { background-color : rgba(158,158,158,.2); }
.side-navigation__list a:active { background-color :rgba(158,158,158,.4); }

.side-navigation__list i {
  max-width: 24px;
  margin: 0px 24px 0px 6px;
}
.side-navigation__list .avatar {
  margin: 0px 20px 0px 12px;
}
.side-navigation__large-area {
  min-height: 40px;
  padding: 16px 4px;
  box-shadow: var(--whiteframe-1dp);
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.005em;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  color: var(--color-default-87)
}

.side-navigation__list-item {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  align-items: center;
  padding: 0px 16px;
  min-height: 32px;
}
.side-navigation__list-item:hover{
  background-color: var(--color-default-12)
}
.side-navigation__list-item .right {
  margin-left: auto;
}
.multi-add-item {
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.multi-add-item:not(:last-of-type):not(:only-of-type) {
  border-bottom: 1px solid var(--color-default-12);
}
.multi-add-item__actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.multi-add-item .row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.multi-add-item_summary {
  margin-top: 8px;
  margin-bottom:8px;
  padding-left: 38px;
}

.multi-add-item .column {
  display: flex;
  flex-direction: column;
}

.multi-add-item_summary, .multi-add-item_edit {
  display: flex;
  flex-direction: row;
}

.multi-add-item__edit-line {
  margin-left: 34px;
}
.checkbox-container {
  position: relative;
  margin: 8px 16px;
  padding: 11px;
  min-width: 18px;
  height: 18px;
}
.checkbox-container label {
  height: 40px;
  width: 40px;
}
.checkbox-input {
  position: absolute;
  height: 40px;
  width: 40px;
  opacity: 0;
  top: 0px;
  left: 0px;
  margin: 0px;
}
.checkbox-box {
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 11px;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  border : solid;
  border-width: 2px;
  border-color: var(--color-default-56);
  background-color: rgba(0,0,0,0);
  transition: background-color 15ms;
}
.checkbox-box-check {
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 0px;
  padding: 13px;
  height: 14px;
  width: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 015ms;
}
.checkbox-label {
  margin-left: 24px;
}
.checkbox-box-check.checked {
  opacity: 100;
}
.checkbox-box-check svg {
  height: 24px;
  width: 24px;
}
.checkbox-box.checked, .checkbox-box.checked.primary {
  border-color: var(--color-primary);
  background-color: var(--color-primary);
}
.checkbox-box.checked.accent {
  border-color: var(--color-accent);
  background-color: var(--color-accent);
}
.checkbox-box.checked.warn {
  border-color: var(--color-warn);
  background-color: var(--color-warn);
}
.checkbox-box.checked.disabled {
  border-color: rgba(0,0,0,0);
  background-color: var(--color-default-26);
}
.chip {
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 18px;
  min-width: 32px;
  min-height: 32px;
  padding: 0px 12px;
  margin: 4px;
  border-radius: 18px;
  color: var(--color-default-87);
  background-color: var(--color-gray-300);
}

.chip.primary { color: white; background-color : var(--color-primary); }
.chip.accent { color: white; background-color : var(--color-accent); }
.chip.warn { color: white; background-color : var(--color-warn); }

.chip.button:active { box-shadow :var(--whiteframe-2dp); }
.chip.button.disabled, .chip.button.disabled:active { 
  box-shadow : none;
  color : rgba(0,0,0,0.24);
  cursor: default;
}

.chip .icon-button-container .icon-button {
  margin: 0 -12px 0 4px;
}
.badge {
  border-radius: 50%;
  background-color: var(--color-primary);
  color: white;
  height: 22px;
  width: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: var(--color-default-87);
  background-color: var(--color-gray-300);
}
.badge__text {
  font-size: 12;
  letter-spacing: .02em;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
}

.badge.primary { color: white; background-color : var(--color-primary); }
.badge.accent { color: white; background-color : var(--color-accent); }
.badge.warn { color: white; background-color : var(--color-warn); }

.message-paragraph {
  font-size: 14px; letter-spacing: .01em; line-height: 24px
}
.message-paragraph-bold {
  font-size: 14px; letter-spacing: .01em; font-weight: 500; line-height: 24px
}
.message-section {
  padding: 8px 0px;
}
.rich-text-editor--container {
  flex: 1 1;
  overflow-y: none;
  overflow-x: none;
  padding: 8px;
  resize: none;
  outline: none;
  border: 0;
}

.rich-text-editor {
  flex: 1 1;
  overflow-y: auto;
  overflow-x: hidden;
  white-space: pre-wrap;
  resize: none;
  outline: none;
  border: 0;
}


.note-edit-area {
  flex: 1 1;
  overflow-y: auto;
  white-space: pre-wrap;
  padding: 8px;
  resize: none;
  outline: none;
  border: 0;
}

/* prevents the default blue border from appearing when clicking */
.no-click-border:focus { 
  outline: none;
  border: 0;
}

.board-palette .whiteframe-3dp {
  color : black;
  right: 0px;
}
.board-palette .menu-contents {
  min-width: 96px
}
.board-palette .color-picker-menu-item {
  padding: 2px 8px 2px 16px;
}
.board-lane {
  display: flex;
  flex-direction: column;
  position: relative;
  flex-shrink: 0;
  width: 256px;
  margin: 8px;
  box-shadow: var(--whiteframe-4px);
}
.board-lane-header {
  display: flex;
  flex-direction: row;
  min-height: 48px;
  align-items: center;
  box-shadow: var(--whiteframe-1dp)
}
.board-lane-body {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1 1;
}
.board-lane-footer {
  display: flex;
  flex-direction: row;
  min-height: 48px;
}
.board-lane-footer button {
  flex : 1 1
}
.board-lane.reorder-target {
  background-color: var(--color-gray-300)
}
.board-lane-reordering-area {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
}
.board-lane.reorder-target.card-target .board-card {
  z-index: 2;
  position: relative;
}

.text-area {
  flex: 1 1;
  overflow-y: auto;
  white-space: pre-wrap;
  padding: 8px 2px;
  resize: none;
  height: unset;
}
.board-card {
  box-shadow: var(--whiteframe-2dp);
  border-radius: 4px;
  background-color: var(--color-primary);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin : 8px;
  min-height: 48px;
  flex-shrink: 0;
  transition: height 1s ease-in-out;
}

.board-card__body {
  margin-top: -8px;
  background-color: white;
  min-height: 32px;
  position: relative;
}

.board-card__footer {
  background-color: white;
  min-height: 32px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.board-card-reordering-area {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
}
.board-card-reordering-area.target {
  background-color: rgba(0,0,0,.21)
}
.feature-boards-lanes {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  overflow-y: auto;
}
.feature-boards-lanes-pad {
  width: 72px;
  flex-shrink: 0;
}
.shareable-view {
  min-height: 128px;
  /* padding: 8px; */
  display: flex;
  flex-direction: column;
  height: 35vw;
  max-height: 28vh;
  margin: 0;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 400px) { 
  .shareable-view {width: 100%;} 
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 400px) {
  .shareable-view {width: 50%;}
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) { 
  .shareable-view {width: 33.332%;} 
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) { 
  .shareable-view {width: 25%; } 
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) { 
  .shareable-view {width: 20%; } 
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) { 
  .shareable-view {width: 15%; } 
}
.shareable-view .card {
  flex: 1 1;
  margin: 8px;
}
.shareable-view-preview {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  margin: -16px;
  background-color: var(--color-gray-200)
}
.shareable-view-title {
  display: flex;
  flex-direction: row; 
}
.shareable-view.reorder-target, .shareable-view.reordering {
  position: relative;
}
.shareable-view.reorder-target .card {
  background-color: var(--color-gray-300)
}
.shareable-view.reorder-target .shareable-view-preview {
  background-color: var(--color-gray-500)
}
.feature-view-reordering-area {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
}

.shareable-view-folder {
  /* min-height: 56px; */
  display: flex;
  flex-direction: column;
  /* max-height: 56px; */
  margin: 0;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 400px) { 
  .shareable-view-folder {width: 100%;} 
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 400px) {
  .shareable-view-folder {width: 50%;}
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) { 
  .shareable-view-folder {width: 33.332%;} 
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) { 
  .shareable-view-folder {width: 25%; } 
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) { 
  .shareable-view-folder {width: 20%; } 
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) { 
  .shareable-view-folder {width: 15%; } 
}
.shareable-view-folder .card {
  flex: 1 1;
  margin: 8px;
}
.shareable-view-folder-title {
  display: flex;
  flex-direction: row; 
}
.shareable-view-folder.reorder-target, .shareable-view-folder.reordering {
  position: relative;
}
.shareable-view-folder.reorder-target .card {
  background-color: var(--color-gray-300)
}
.feature-view-folder-reordering-area {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
}

.multicontactchooser__linelabel {
  padding: 9px 4px;
  margin: 4px 2px -1px 2px;
  min-height: 30px;
  min-width: 40px;
  flex-direction: row;
  text-align: center;
  
  border-width: 0 0 0px 0; 
  line-height: 26px;
  height: 30px;
  
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.010em;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  outline: none;
}
.multicontactchooser__row {
  display: flex;
  min-height: 30px;
  flex: 1 1;
  flex-direction: row;
  padding: 9px 4px;
  margin: 4px 2px -1px 2px;
  line-height: 26px;
  height: 30px;
}
.multicontactchooser__row .multicontactchooser__linelabel {
  padding: 0px 8px 0px 0px;
  margin: 0;  
}
.divider {
  width : 100%;
  border-bottom: solid 1px var(--color-default-12)
}
.contact-area .chip {
  margin: 10px 2px 9px 2px;
}

.message__quoted-text {
  margin-top: 8px;
  padding: 16px 0px;
}

.message__quote-header {
  border-top: solid 1px var(--color-default-12);
  padding-top: 8px;
}

.message__quote-header b {
  width: 80px;
  line-height: 20px;
}
.expansion-panel {
  box-shadow: var(--whiteframe-2dp);
  margin: 16px 8px;
  border-radius: 4px;
}

.expansion-panel__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 24px;
  height: 64px;
}

.expansion-panel__content {
  white-space: pre-wrap;
  padding: 0 24px;
}
.avatar {
  height: 40px;
  width: 40px;
  display: inline-block;
}
.avatar, .avatar-image, .avatar-placeholder, .avatar-placeholder-over{
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.avatar-placeholder-anchor {
  max-width: 0px;
  max-height: 0px;
  position: relative;
}

.avatar-placeholder, .avatar-placeholder-over {
  position: absolute;
  top: 0;
  left: 0;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.avatar-placeholder-over {
  z-index: 1;
}
.shareable-views-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 100%;
}

.shareable-views-list-header {
  height: 56px;
  margin-left: 8px;
}
.folder-option {
  cursor: pointer;
  height: 48px;
  line-height: 48px;
  display: flex;
  flex-direction: row;
}
.folder-option i {
  line-height: 48px;
  margin: auto 6px;
  padding: 0 16px;
}
.folder-option div {
  line-height: 48px;
  align-self: auto;
}
.folder-option:hover { background-color : rgba(158,158,158,.2); }
.folder-option:active { background-color :rgba(158,158,158,.4); }
.board-preview {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 100%;
  padding: 4px 0px;
}

.board-preview-lane {
  background-color: white;
  border-radius: 1px;
  max-width: 80px;
  min-width: 80px;
  margin: 0px 2px;
  height: 100%;
  font-size: 10px;
  box-shadow: var(--whiteframe-1dp)
}

.board-preview-lane-header {
  border-bottom: 1px solid var(--color-gray-500);
  padding: 0px 2px;
  line-height: 20px;
}

.board-preview-card {
  color: white;
  background-color: var(--color-green-500);
  border-radius: 1px;
  margin: 4px 2px;
  max-height: 16px;
  line-height: 16px;
  overflow: hidden;
  font-size: 10px;
  padding: 0px 2px;
  box-shadow: var(--whiteframe-1dp) 
}
.note-preview {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 100%;
  padding: 8px;
  font-size: 10px;
  white-space: pre-wrap;
  color: black;
}
.item-views-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
}

.empty-item-views-message {
  display: flex;
  flex-direction: column;
  min-height: 20vh;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: -1;
}

.empty-item-views-message__header {
  color: var(--color-default-56);
  margin: 8px 0px;
  font-size: 45px; font-weight: 400; line-height: 48px;
  text-align: center;
}
.empty-item-views-message__body {
  color: var(--color-default-56);
  text-align: center;
}

.empty-item-folders-message {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  min-height: 40px;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: -1;
}

.empty-item-folders-message__body {
  color: var(--color-default-56);
  text-align: center;
}
.chats-list__chat {
  display: flex;
  flex-direction: row;
  padding: 8px;
}

.chats-list__chat__content {
  display: flex;
  flex-direction: column;
  flex: 1 1;
} 

.chats-list__chat__avatar {
  margin-right: 8px;
}
.message-group {
  display: flex;
  flex-direction: row;
  padding: 8px;
}

.message-group__list {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.message-group__list.system-message {
  flex-direction: row;
  flex: 1 1;
}

.chat-message__sent, .chat-message__recieved {
  flex: 1 1;
  white-space: pre-wrap;
  padding: 8px 12px;
  margin: 0px 8px 8px 8px;
  resize: none;
  outline: none;
  border: 0;
  border-radius: 16px;
  color: var(--color-default-87);
}

.chat-message__sent {
  align-self: flex-end;
  background-color: white;
}

.chat-message__recieved {
  align-self: flex-start;
  background-color: var(--color-gray-300)
}

.chat-message__system {
  flex: 1 1;
  white-space: pre-wrap;
  padding: 12px;
  margin: 0px -8px 8px -8px;
  resize: none;
  outline: none;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  border: 0;
  color: var(--color-default-56);
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.chat-editor {
  display: flex;
  flex-direction: row;
}

.chat-editor .input-container {
  border-radius: 8px;
  line-height: 20px;
  border: solid 1px var(--color-default-12);
  background-color: white;
  padding: 0px 4px;
}

.chat-editor textarea {
  border-bottom: none !important;
  background-color: white !important;
}

.message-group__time-area {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.system-message .message-group__time-area {
  padding: 12px 8px;
  margin: 0px -8px 8px -8px;
  resize: none;
  outline: none;
  text-align: center;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.contact-summary {
  display: flex;
  flex-direction: row;
  padding: 8px;
}

.contact-summary__content {
  display: flex;
  flex-direction: column;
  align-content: center;
  flex: 1 1;
} 

.contact-summary__avatar {
  margin-right: 8px;
}
.contact-summary {
  display: flex;
  flex-direction: row;
}

.contact-summary__content {
  display: flex;
  flex-direction: column;
  align-content: center;
  flex: 1 1;
  padding: 8px;
} 

.contact-summary__avatar {
  margin-right: 8px;
}

.message-summary {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  margin : 8px;
}

.message-summary__end {
  text-align: end
}
.message-header__time {
  align-self: center;
  padding: 0px 16px;
}
.avatar-picker:hover { box-shadow :var(--whiteframe-3dp); }
.avatar-picker:active { box-shadow :var(--whiteframe-8px); }
.quick-list-preview {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  padding-top: 8px;
}

.quick-list-preview__item {
  font-size: 10px;
  line-height: 16px;
  padding: 0px 6px;
  line-height: 20px;
  display: flex;
  flex-direction: row
}

.quick-list-preview__section {
  font-size: 12px;
  border-bottom: 1px solid var(--color-default-12);
  padding: 0 2px 0 35px;
  margin: 0 0 2px 0;
  line-height: 24px;
  font-weight: 500;
}

.quick-list-preview__checkbox {
  margin: 4px 10px 4px 6px;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  border: solid;
  border-width: 1px;
  border-color: var(--color-default-56);
}

.quick-list-preview__checkbox--checked {
  margin: 4px 10px 4px 6px;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  border: solid;
  border-width: 1px;
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  position: relative;
}

.quick-list-preview__checkbox--checked svg {
  position: absolute
}
.feature-quick-list-items {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  overflow-y: none;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  transition: -webkit-transform 225ms cubic-bezier(0, 0, 0.2, 1);
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1);
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1), -webkit-transform 225ms cubic-bezier(0, 0, 0.2, 1);
}
.feature-quick-list-items.show-subheader {
  -webkit-transform: translateY(40px);
          transform: translateY(40px);
}
.feature-quick-list-items-pad {
  height: 72px;
  flex-shrink: 0;
}
.feature-quick-list-items-pad.show-subheader{
  /* height: 112px; */
}
.board-card {
  box-shadow: var(--whiteframe-2dp);
  border-radius: 4px;
  background-color: var(--color-primary);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin : 8px;
  min-height: 48px;
  flex-shrink: 0;
  transition: height 1s ease-in-out;
}

.board-card .card-body {
  background-color: white;
  min-height: 32px;
  padding: 8px;
  position: relative;
}
.quicklist-item {
  position: relative;
}
.quicklist-item.selected {
  background-color : var(--color-gray-300)
}
.quicklist-item-reordering-area {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-color: rgb(255,255,255,.21);
}
.quicklist-item-reordering-area.target {
  background-color: rgba(0,0,0,.21);
}

.quick-list-item__right-spacer {
  width: 48px;
}
.quicklist-section {
  position: relative;
  min-height: 56px;
}
.quicklist-section.selected {
  background-color : var(--color-gray-300)
}
.quicklist-section .input-container {
  padding: 0px 4px;
}
.quicklist-section input { 
  font-size: 20px;
  font-weight: 500;
  line-height: 56px;
  min-height: 56px;
  padding: 0px 2px;
  letter-spacing: .005em;
}
.quicklist-section-reordering-area {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-color: rgb(255,255,255,.21);
}
.quicklist-section-reordering-area.target {
  background-color: rgba(0,0,0,.21);
}
.quick-list-section__right-spacer {
  width: 48px;
}
body { font-family: "Roboto", "Helvetica", "Arial", sans-serif; }

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, Chrome and Opera */
}

.flexbox-row {
  display: flex;
  flex-direction: row;
}

.flexbox-row.margin-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.toast {
  background-color: black;
  color: white;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: -48px;
  border-radius: 2px;
  padding: 6px 24px;
  transition: margin-bottom 225ms cubic-bezier(0, 0, 0.2, 1);
}
.toast__actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.toast.shown {
  margin-bottom: 0px;
}
.shareable-view-folder {
  /* min-height: 56px; */
  display: flex;
  flex-direction: column;
  /* max-height: 56px; */
  margin: 0;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 400px) { 
  .shareable-view-folder {width: 100%;} 
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 400px) {
  .shareable-view-folder {width: 50%;}
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) { 
  .shareable-view-folder {width: 33.332%;} 
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) { 
  .shareable-view-folder {width: 25%; } 
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) { 
  .shareable-view-folder {width: 20%; } 
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) { 
  .shareable-view-folder {width: 15%; } 
}
.shareable-view-folder .card {
  flex: 1;
  margin: 8px;
}
.shareable-view-folder-title {
  display: flex;
  flex-direction: row; 
}
.shareable-view-folder.reorder-target, .shareable-view-folder.reordering {
  position: relative;
}
.shareable-view-folder.reorder-target .card {
  background-color: var(--color-gray-300)
}
.feature-view-folder-reordering-area {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
}

.contact-summary {
  display: flex;
  flex-direction: row;
}

.contact-summary__content {
  display: flex;
  flex-direction: column;
  align-content: center;
  flex: 1;
  padding: 8px;
} 

.contact-summary__avatar {
  margin-right: 8px;
}

.message-summary {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin : 8px;
}

.message-summary__end {
  text-align: end
}